import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Cookies from 'js-cookie' 
import store from './alvernoDat/stores/store';


const params = new URLSearchParams(window.location.search)

if(params.get('redirect_uri')) {
  Cookies.set('redirect_uri', params.get('redirect_uri'), { expires: 1 })
}
if (params.get('state')) {
  Cookies.set('state', params.get('state'), { expires: 1 })
}
if (params.get('scope')) {
  Cookies.set('scope', params.get('scope'), { expires: 1 })
}
if (params.get('client_id')) {
  Cookies.set('client_id', params.get('client_id'), { expires: 1 })
}

const app = createApp(App)

app.use(router)
app.use(store)
app.mount('#app')