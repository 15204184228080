<template>
  <div id="create" class="container" :style="textPrimaryStyle">
     
    <h1 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">CheckOut</h1>


    <span class="helper">First Name<br />(Please enter the name you use for medical records)</span>
    <input type="text" placeholder="Enter first name" id="firstname-field" v-model="firstName" @focus="onInputFocus">

    <span class="helper">Last Name</span>
    <input type="text" placeholder="Enter last name" id="lastname-field" v-model="lastName" @focus="onInputFocus">

    <span class="helper">
      Email Address
    </span>
    <input type="text" placeholder="Enter email address" id="email-field" v-model="email" @focus="onInputFocus">

    <span class="helper">Address</span>
    <input type="text" id="address" placeholder="Enter street address" v-model="address" @focus="onInputFocus">
    <input type="text" id="city" placeholder="Enter city" v-model="city" @focus="onInputFocus">
    <select name="state" id="state" v-model="state" @focus="onInputFocus">
      <option value="" selected disabled hidden @focus="onInputFocus">Select State</option>
      <option value="AL">Alabama</option>
      <option value="AK">Alaska</option>
      <option value="AZ">Arizona</option>
      <option value="AR">Arkansas</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DE">Delaware</option>
      <option value="DC">District Of Columbia</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="IA">Iowa</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="ME">Maine</option>
      <option value="MD">Maryland</option>
      <option value="MA">Massachusetts</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MS">Mississippi</option>
      <option value="MO">Missouri</option>
      <option value="MT">Montana</option>
      <option value="NE">Nebraska</option>
      <option value="NV">Nevada</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NY">New York</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VT">Vermont</option>
      <option value="VA">Virginia</option>
      <option value="WA">Washington</option>
      <option value="WV">West Virginia</option>
      <option value="WI">Wisconsin</option>
      <option value="WY">Wyoming</option>
    </select>
    <input type="number" id="zip" placeholder="Enter zip code" v-model="zipCode" @focus="onInputFocus">
   
    <div>
         <span class="helper">Credit Card</span>
        <div id="card-element" />
        
    </div>
    <button @click="submitPayment" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
        Check Out
    </button>
    
    <span class="spacer"></span>
    <router-link to="/login/help" class="link-light" :style="linkSecondaryStyle">Need help?</router-link>
    <span class="spacer"></span>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2';
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters,mapActions } from 'vuex';
const ELEMENT_TYPE = 'card'
import router from '../../../router/index'


export default {
  components: {
    
  },
  data() {
    return {
    stripe: null,
    elements: null,
    card:null,
    firstName: '',
    lastName: '',
    email: '',

      
     
      password: '',
      userInfoComplete: false,
   
      address: '',
      city: '',
      state: '',
      zipCode: '',
      whitelabel: this.$root.config.whitelabel,
      textColor: this.$root.config.textColorPrimary,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
  
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      buttonStyle: {
        backgroundColor: tinycolor(this.$root.config.primaryColor).darken(15).toHexString()
      },
      buttonStyleWhitelabel: {
        backgroundColor: this.$root.config.primaryColor
      },
    }
  },
  computed: {
          ...mapGetters([
      'stripeClientSecret',
    ]),
    
    validateMainForm() {
      let isValid = true

      if(this.firstName.length > 0) {
        this.$el.querySelector('#firstname-field').classList.remove('error')
      } else {
        this.$el.querySelector('#firstname-field').classList.add('error')
        isValid = false
      }

      if(this.lastName.length > 0) {
        this.$el.querySelector('#lastname-field').classList.remove('error')
      } else {
        this.$el.querySelector('#lastname-field').classList.add('error')
        isValid = false
      }

      if(this.validateEmail(this.email)) {
        this.$el.querySelector('#email-field').classList.remove('error')
      } else {
        this.$el.querySelector('#email-field').classList.add('error')
        isValid = false
      }

      if(this.address.length > 0) {
        this.$el.querySelector('#address').classList.remove('error')
      } else {
        this.$el.querySelector('#address').classList.add('error')
        isValid = false
      }

      if(this.city.length > 0) {
        this.$el.querySelector('#city').classList.remove('error')
      } else {
        this.$el.querySelector('#city').classList.add('error')
        isValid = false
      }

      if(this.state.length > 0) {
        this.$el.querySelector('#state').classList.remove('error')
      } else {
        this.$el.querySelector('#state').classList.add('error')
        isValid = false
      }

      if(this.zipCode.length > 4) {
        this.$el.querySelector('#zip').classList.remove('error')
      } else {
        this.$el.querySelector('#zip').classList.add('error')
        isValid = false
      }
      return isValid;
    },
  },

  async mounted() {
    this.stripe = await loadStripe('pk_test_51J4mLWBUPKgFjnQhRnulhD9n6cUuyU9MzImidElbzBFtVrvdmCQ69zsxp6ZerziNgW9Ta1pxHDRmHZ3zfYu2a65F00cqYzT99K');
    this.elements = this.stripe.elements();
    this.card = this.elements.create(ELEMENT_TYPE);
    this.card.mount("#card-element");

    this.card.on('change', ({error}) => {
        let displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });

    window.addEventListener('keydown', this.onKeyPress)
  },
  methods: {
    ...mapActions([ 'confirmPayment' , 'addMessage', 'clearCart', 'listenToProductList']),
    onKeyPress(event) {
      if(event.key === "Enter") {
        if(this.userInfoComplete) {
          this.onConnectClick()
        } else {
          this.onCreateClick()
        }
      }
    },

    async submitPayment(){
      if(this.validateMainForm){
        const billingDetails = {
        name:this.firstName+' '+this.lastName,
        email:this.email,
        address: {
          city:this.city,
          line1: this.address,
          state:this.state,
          postal_code: this.zip
        }
      };
        console.log(billingDetails);
        await this.stripe.confirmCardPayment(this.stripeClientSecret,{
          payment_method: {
            card: this.card,
            billing_details: billingDetails
          }
      }).then((result) => {
        if (result.error) {
          this.addMessage({
            messageClass: 'warning',
            message: result.error.message,
          })
          return
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            console.log("succeed!") 
            this.confirmPayment(result.paymentIntent.id)
            console.log("Confirmed!")
            this.clearCart();
            this.listenToProductList();
            router.push('/dat/alverno')
          }
        }
      });
      }else{
        this.addMessage({
            messageClass: 'warning',
            message: 'Information imcomplete!',
          })
          return
      }
    },
    onInputFocus(e) {
      e.target.classList.remove('error')
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return re.test(String(email).toLowerCase())
    }
  }
}
</script>

<style scoped>

h1{
  margin-top:40px;
  margin-bottom: 50px;
}

#card-element{
    width: 300px;
}
.helper {
  position: relative;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left;
  width: 300px;
}

.row {
  text-align: left;
  width: 300px;
}


.animate-fade {
  animation: fadeIn 0.3s forwards;
  animation-timing-function: ease-out;
  transform-origin: top;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.font-10 {
  font-size: 10px;
}


.hidden-row {
  text-align: left;
  width: 300px;
}

label {
  font-size: 12px;
  vertical-align: 2px;
}

label a {
  text-decoration: underline;
}


</style>
