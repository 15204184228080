<template>
  <div id="help" class="container" :style="textPrimaryStyle">
    <img alt="Logo" :src="logoPath" :class="[whitelabel ? 'logo' : 'luminate-logo']">
    <h4 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Need Help?</h4>
    <p>We would love to hear from you. Please use the contact form below and send us a message. Whether you need help with the service, or want to share any feedback, we're here for you!</p>
    <span class="spacer"></span>
    <hr :style="hrStyle">
    <span class="spacer"></span>
    <h4 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Contact details</h4>
    <p>
      <span class="email-icon">
        <SVGIcon v-bind:color="textColor" v-bind:size="12" config="email" />
      </span>
      <b>Email:</b> <a :style="linkPrimaryStyle" href="mailto:help@luminatehealth.com">help@luminatehealth.com</a><br />
      Please include the following with your email request regarding test results:
      <ul>
        <li>Name (include first and last names)</li>
        <li>Date of Birth</li>
        <li>Date of Test</li>
        <li>Location of Test</li>
      </ul>
    </p>
    <hr :style="hrStyle">
    <span class="spacer"></span>
    <router-link to="/login/retrieve-password" :style="linkPrimaryStyle">Forgot user name or password?</router-link>
    <span class="spacer"></span>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import SVGIcon from '../components/SVGIcon'

export default {
  name: 'Help',
  components: {
    SVGIcon
  },
  data() {
    return {
      name: this.$root.config.name,
      whitelabel: this.$root.config.whitelabel,
      logoPath: this.$root.config.logoPath,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      hrStyle: {
        borderTop: '1px solid' + tinycolor(this.$root.config.primaryColor).lighten(20).toHexString()
      }
    }
  }
}
</script>

<style scoped>
  hr, ul {
    width: 90%;
    max-width: 960px;
    text-align: left;
  }

  hr {
    opacity: 0.3;
  }

  a {
   text-decoration: underline; 
  }

  .email-icon {
    display: inline-block;
    margin-right: 10px;
  }
</style>