<template>
	<li>
		<h3 class="product-name">{{ cartItem.name }}</h3>
		<h3 class="product-price">$ {{ cartItem.price }},00 </h3>
		<btn btnColor="btn-small btn-common" class="product-remove" @click="removeItem">X</btn>	
	</li>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import btn from '../Button.vue'
	export default {
		props: ['cartItem'],
		components: {
			btn,
		},
		computed: {
			...mapGetters([ 'transactionID' ]),
		},
		methods: {
			...mapActions([ 'removeTestInCart' ]),
			removeItem() {
				const body = { 
				"quantity" : -1,
				"testId" : this.cartItem.testId,
				"transactionId":this.transactionID
				}
				this.removeTestInCart(body);
			}
		}
	}
</script>

<style lang="scss" scoped>
  .product-name {
    box-sizing: border-box;
  }
  .product-price {
    font-size: 1.2em;
    
  }
  .product-remove {
    width: 40px;
	height: 40px;
	padding: 10px;
  }

</style>