<template>
  <li>
    <p class="cp" v-if="isPayed=='A'"><span style="font-weight:bold">{{ orderCreateDate }}</span> | Fullfilled | Order# {{orderId}} | Total ${{orderItem.totals/100 }}</p>
    <p class="cp" v-if="isPayed=='B'"><span style="font-weight:bold">{{ orderDate }}</span> | Payed | Order# {{ orderId }} | Total ${{orderItem.totals/100 }}</p>
    <p class="cp" v-if="isPayed=='C'"><span style="font-weight:bold">{{ orderDate }}</span> | Refunded | Order# {{ orderId }} | Total ${{orderItem.totals/100 }}</p>
    <a class="right" href="#" @click.prevent="active = !active">View details</a>
        <br>
    <div v-if="active" class="details">
      <DetailContent
          v-for="test in testList"
          :test="test"
          :key="test.id"
          class="checkout-product"
      />
      
      <p v-if="isPayed=='C'" style="font-size:12px"> Refund at: {{orderRefundDate }}</p>
      <p v-if="isPayed=='B'" style="font-size:12px"> Valid until: Nov 28,2021{{orderItem.createdDate }}</p>
      <btn btnColor="btn btn-medium btn-common" v-if="isPayed=='A'" @click="goToCheckout">Pay Now</btn>
      <btn btnColor="btn btn-small btn-info" class="btn-contact" v-if="isPayed=='B'" @click="showModal">Contact Us</btn>
      <Modal v-show="isModalVisible" :order="order" @close="closeModal"></Modal>
    </div>
  </li>
</template>

<script>

    import DetailContent from './OrderDetailContent.vue'
    import btn from '../Button.vue'
    import Modal from './modal.vue'
    import { mapGetters, mapActions } from 'vuex';
  
	export default {
		props: ['orderItem'],
        data(){
          return{
            order: null,
            testList:null,
            isModalVisible: false,
            active: false,
            orderRefundDate: null,
            orderCreateDate: null,
            orderFullfillDate: null,
          }
        },
        components: {
            DetailContent,
            Modal,
            btn
        },
        mounted() {
            let filterArr = this.orders.filter((item) => {
                return item.id == this.orderItem.id
            });
            if (filterArr.length > 0) {
                this.order = filterArr[0];
                this.testList = this.order.orderTests;    
                
            }

            const statusList = this.orderItem.orderStatusHistoryList;
            statusList.forEach(element=>{
              if(element.status==="REFUND"){
                const date = element.createdDateTime;
                const month = date.substring(5,7);
                const day = date.substring(8,10);
                const year = date.substring(0,4);
                this.orderRefundDate= month+"/"+day+"/"+year;
              }else if(element.status==="PAYMENT_COMPLETED"){
                this.orderCreateDate=element.createdDateTime;
              }else if(element.status==="FULFILLED"){
                this.orderFullfillDate=element.createdDateTime;
              }
            })
        },
		methods: {
      ...mapActions([ 'refund' ]),
      backToLast(){
          this.$router.push('/dat/order-history') 
      },
      goToCheckout(){
          this.$router.push('/dat/checkout') 
      },
      showModal() {
          this.isModalVisible = true;
        },
      closeModal() {
        this.isModalVisible = false;
      }
		},
    computed: {
      ...mapGetters([ 'orders' ]),
  
      orderDate(){
        const date = this.orderItem.createdDateTime;
        const month = date.substring(5,7);
        const day = date.substring(8,10);
        const year = date.substring(0,4);
        return month+"/"+day+"/"+year
      },
      orderId(){
        return this.orderItem.id;
      },
      isPayed(){
        if(this.orderItem.status=="FULFILLED"){
          return "A";
        }else if(this.orderItem.status=="PAYMENT_COMPLETED"){
          return 'B';
        }else{
          return 'C';
        }          
      }     
            
    }
      
	}
</script>

<style lang="scss" scoped>
.date{
  margin-left: 90px;
}
.btn{
  color:rgb(10, 9, 9);
  font-size: 15px;
  margin-left:300px;
}
  
.checkout-product {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(64, 65, 70, 0.1);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  
}
.btn:hover{
    color: green;
}

.cp{
  color: #4c4c4d;
  font-family: BogleWeb,Helvetica Neue,Helvetica,Arial,sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 15px;
  width:800px
}
.right{
  color: #4c4c4d;
  font-family: BogleWeb,Helvetica Neue,Helvetica,Arial,sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
 
  width:100px;
  font-size: 15px;

}
.details{
  margin-left: 100px;
  margin-block: 10px;

}
.btn-conatct{
  color:black;
}

</style>