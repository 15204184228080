<template>
  <svg xmlns="http://www.w3.org/2000/svg" view-box="0 0 100 100" :width="size" :height="size" v-bind:style="styleObject">
    <circle :cx="size / 2" :cy="size / 2" :r="size / 2" />
  </svg>
</template>

<script>
export default {
  props: [
    'size'
  ],
  data() {
    return {
      isAnimating: false,
      strokeDashoffset: 0,
      strokeDashSize: 0,
      styleObject: {
        strokeWidth: this.size / 2
      }
    }
  },
  mounted() {
    let circle = this.$el.querySelector('circle')
    circle.style.strokeDasharray = circle.getTotalLength()
    circle.style.strokeDashoffset = circle.getTotalLength()

    this.strokeDashSize = circle.getTotalLength()

    this.isAnimating = true
    window.requestAnimationFrame(this.animate.bind(this))
  },
  updated() {
    let circle = this.$el.querySelector('circle')
    this.strokeDashSize = circle.getTotalLength()
  },
  beforeUnmount() {
    this.isAnimating = false
  },
  methods: {
    animate() {
      let circle = this.$el.querySelector('circle')
      circle.style.strokeDashoffset = this.strokeDashSize
      this.strokeDashSize += 1
      if(this.isAnimating) {
        window.requestAnimationFrame(this.animate.bind(this))
      }
    }
  }
}
</script>

<style scoped>
  svg {
    position: absolute;
    left: 15px;
    top: 13px;
    overflow: visible;
    filter: drop-shadow(0px 0px 20px #0e4199)
  }

  circle {
    fill: none;
    stroke: #fefefe;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
  }
</style>