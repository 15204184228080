<template>
  <div class="container">  
    <div v-if="test" class="test-details">
      <h3 class="text-center">{{test.name}}</h3>
      <p class="description">{{test.description}}</p>
      <h3 class="text-cent">${{test.price}}</h3>

      <div class="cart-total">
        <h3>In Cart</h3>
        <h4>$ {{cartValue}}</h4>
      </div> 
      <div class="button-container">
         <td>
            <btn btnColor="btn btn-medium btn-common" class="backBtn" @click="backToMain">
               Back to tests
            </btn>
          </td>
          <td>
            <btn btnColor="btn btn-medium btn-common" class="addBtn" v-if="!isAdd" @click="addItem">
              Add to cart
            </btn>
            <btn btnColor="btn btn-medium" class="text-muted" v-if="isAdd" @click="removeItem">
              Remove from cart
            </btn>
          </td>
      </div>
      </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';

import btn from '../Button.vue'
export default {
  components: {
    btn
  },
  data(){
    return{
      isAdd: false,
      test: null
    }
  },

  computed: {
    ...mapGetters([ 'cartValue', 'transactionID', 'products', 'cartItemList']),
  },
  
  mounted() {
    let id = this.$route.params.id;
    console.log(this.$route.params)
    console.log(this.products)
    if (this.products.length >= id) {
        let filterArr = this.products.filter((item) => {
          return item.testId == id
        });
        if (filterArr.length > 0) {
          this.test = filterArr[0];
          this.isAdd = filterArr[0].isAdd;
        }
    }
  },

  methods: {
     ...mapActions(['addTestToCart','removeTestInCart']),
    addItem() {
      this.isAdd = true;
      this.addTestToCart(this.test); 
    },
    removeItem() {
      this.isAdd = false;
			this.removeTestInCart(this.test)
		},
    backToMain(){
      this.$router.push('/dat/alverno') 
    }
  },
}
</script>

<style lang="scss" scoped>

.test-details {
    display: block;
    justify-content: center;
    flex-direction: column;
    background-color: aliceblue;
    font-family: Arial;

    border-radius: 15px;
    margin: 60px;
    h3.text-center {
      padding-top:30px;
    }
    p.description {
      text-align: center;
      margin-left: 20px;
      padding: 20px;
      line-height: 1.5rem;
    }

    .button-container {
        button {
            width: 150px;
            border: none;
            padding: 10px;
            border-radius: 5px;
            margin: 0 25px 50px;
            cursor: pointer;
        }
        button.addBtn {
          margin-left: 600px;
        }
        button.text-muted {
            color: white;
            background-color: grey;
            margin-left: 600px;
        }
        button.backBtn {
          margin-left: 40px;
        }
    }

}

</style>