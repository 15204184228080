<template>
<div class="item-detail">
	<p class="item-info">{{ test.name }} <br>${{ test.price/100 }}</p>
</div>
</template>

<script>
export default {
    props: [ 'test' ],
}
</script>

<style lang="scss" scoped>
.item-detail {
  box-shadow: 0px 0px 8px rgba(89, 90, 95, 0.1);
  box-sizing: border-box;
  margin: 0.8em 1.2em;
  width: 100%;
  height: 60px;
  
}
.item-info{
	margin-top:10px;
	margin-bottom:10px;
	font-size:12px;
	font-family: BogleWeb,Helvetica Neue,Helvetica,Arial,sans-serif;

}
</style>