<template>
  <div class="container">
    <Header />
    <h1 v-if="cartIsEmpty">Your cart is empty</h1>
      <div class="checkout-box">
        <ul class="checkout-list">
          <transition-group name="fade">
            <CartItem
              v-for="cartItem in cartItemList"
              :cartItem="cartItem"
              :key="cartItem.id"
              class="checkout-product"
            />
          </transition-group>
        </ul>
      </div>
      
      <h3 class="total" v-if="!cartIsEmpty">
        Total: $ {{ cartValue }}, 00
      </h3>
      <div>
        <button class='back-btn' @click="backToMain">Back to tests</button>
        <button class='payment-btn' v-if="isLoggedIn" @click="checkout">Pay Now</button>
        <button class='payment-btn' v-else><router-link to="/dat/login">Log in to continue</router-link></button>  
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CartItem from './CartItem.vue'
import Header from '../Header.vue'

export default {
  components: {
      CartItem,
      Header
    },
  computed: {
    ...mapGetters([
      'cartItemList',
      'cartValue',
      'transactionID',
      'isLoggedIn',
      'isAssigned'
    ]),
    cartIsEmpty(){
      if(this.cartItemList.length){
        return false
      }else{
        return true
      }
    }
  },
  methods: {
    ...mapActions([
     'createPaymentIntention',
     'addMessage',
     'assignCartToPatient'
    ]),
    backToMain(){
      this.$router.push('alverno') 
    },
    checkout(){
      if(this.cartItemList.length){
        if(!this.isAssigned){
          this.assignCartToPatient(this.transactionID);
          setTimeout(()=>{
            this.createPaymentIntention(this.transactionID);
          },1500)
        }
        this.$router.push('/dat/checkout')
      }else{
        this.addMessage({
            messageClass: 'warning',
            message: 'Your cart is empty!',
          })
          return
      }
      
    },
  }
    
}
</script>

<style lang="scss" scoped>
.checkout-box {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    margin: 50px auto;
    box-sizing: border-box;
    padding: 0;
}
.checkout-list {
  padding: 50;
}
.checkout-product {
  display: grid;
  height: 120px;
  width:90%;
  grid-template-columns: 4fr 3fr 2fr;
  background-color: rgb(240, 236, 236);
  box-shadow: 0px 0px 10px rgba(73, 74, 78, 0.1);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin: 1em 0;
}
  .checkout-product * {
    place-self: center;
  }

  .total {
    font-size: 2em;
    font-weight: bold;
    align-self: flex-end;
    margin-right: 250px;
  }
.back-btn {
  width:250px;
}
.payment-btn {
  width:250px;
  margin-left: 600px;
}

  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
  }
  .fade-enter, .fade-leave-to {
    transform: translateX(40px);
    opacity: 0;
  }
</style>