<template>
  <span class="error-message">
    <div class="icon-container">
      <SVGIcon config="exclamation" color="#FAFAFA" size="30" />
    </div>
    <p>{{text}}</p>
  </span>
</template>

<script>
import SVGIcon from '../components/SVGIcon'

export default {
name: 'ErrorMessage',
  components: {
    SVGIcon
  },
  props: ['text']
}
</script>

<style scoped>
  .error-message {
    position: relative;
    color: white;
    width: 260px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    padding: 5px 20px;
    font-weight: bold;
    font-size: 11px;
    background-color: rgba(243, 22, 107, 0.7);
    border: 1px solid rgba(243, 22, 107, 1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin: 5px 0;
    animation: error-in 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  .icon-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-bottom: 2px;
  }

  p {
    margin-left: 20px;
  }

  @keyframes error-in {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>