import config from "../config.json"
import http from "./httpService"

export async function login(username, password) {
  return http.post(config.apiUrl + "/api/v1/auth", {
    "username": username,
    "password": password
  })
}

export async function loginLegacy(username, password) {
  const params = new URLSearchParams()
  params.append('username', username)
  params.append('password', password)
  const cfg = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  return http.post(config.apiUrl +  "/login", params, cfg)
}

export async function authorizaionCode(client_id, scope, state, redirect_uri) {
  const params = new URLSearchParams()
  params.append('client_id', client_id)
  params.append('scope', scope)
  params.append('state', state)
  params.append('redirect_uri', redirect_uri)
  params.append('user_oauth_approval', true)
  const cfg = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  return http.post(config.apiUrl +  "/oauth/authorization_code", params, cfg)
}
