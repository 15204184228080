import config from "../config.json"
import http from "./httpService"

export async function checkUsername(username) {
  return http.post(config.apiUrl + "/api/v1/user/username-check", {
    "username": username
  })
}

export async function checkPassword(password) {
  return http.post(config.apiUrl + "/api/v1/user/password-check", {
    "password": password
  })
}

export async function createAccount(userInfo) {
  return http.post(config.apiUrl + "/api/v1/user/signup", userInfo)
}
