import http from '../../services/httpService';
import config from '../../config.json';

var transactionId = "";

// Get Lab1 tests list 
export async function listenToProductList({commit}) {
	const data = await http.get(config.apiUrl + "/admin/api/lab-order/1/tests")
	.then(res=>{
		return res.data 
	})
	return commit('UPDATE_PRODUCT_LIST',data)
}

// Add a new test to cart and get transactionId
export async function addTestToCart({commit}, test) {
	let body = { "testId" : test.testId,
				"transactionId":transactionId 
				}
	const data = await http.post(config.apiUrl+"/admin/api/lab-order/cart/test/add", body)
	.then(res=>{
		return res.data
	})
	commit('SET_TRANSACTIONID', data.transactionId)
	transactionId = data.transactionId
	let message_obj = {
		message: `Add ${test.name} to cart successfully`,
		messageClass: "success",
		autoClose: true
	}
	
	commit('ADD_MESSAGE', message_obj);
	getShoppingCart({commit}, transactionId)
	commit('ADD_STATUS', test.testId)
}

export async function removeTestInCart({commit},test) {
	let body = { 
				"quantity" : -1,
				"testId" : test.testId,
				"transactionId":transactionId 
				}
	await http.post(config.apiUrl+"/admin/api/lab-order/cart/test/add", body).then(res=>{
		return res.data
	})
	getShoppingCart({commit}, transactionId)
	commit('REMOVE_STATUS', test.testId)
}

export async function getShoppingCart({commit}, transactionId) {
	console.log(transactionId)
	let data = await http.get(config.apiUrl + "/admin/api/lab-order/cart/?transactionId=" + transactionId)
	.then(response => {return response.data})
	if (data) {
		commit('SET_CART',data)
	} else {
		console.log("Empty Cart");
	}
}

export async function assignCartToPatient({commit},transactionId) {
	console.log(transactionId)
	await http.post(config.apiUrl + "/admin/api/lab-order/cart/patient/assign?transactionId="+transactionId)
	console.log("Cart assigned!!")
	commit('SET_ASSIGN_STATUS')

}

export async function createPaymentIntention({commit}, transactionId) {
	let data = await http.post(config.apiUrl + "/admin/api/lab-order/cart/payment/intent/create?transactionId="+transactionId)
	.then(response => {return response.data})
	console.log(data)
	commit('SET_PAYMENT_INTENTION',data)
}

export async function confirmPayment(_,paymentIntentId){
	await http.post(config.apiUrl +"/admin/api/lab-order/order/payment/confirm?intentId="+ paymentIntentId)
}

export async function refund(_,paymentIntentId) {
	await http.post(config.apiUrl + "/admin/api/lab-order/order/payment/refund?intentId="+paymentIntentId)
}

export const updateLoginStatus = ({commit}, loginStatus) => {
	console.log(loginStatus)
	commit('SET_LOGIN', loginStatus);
}
export async function getPatientOrder({commit}){
	let orders = await http.get(config.apiUrl + "/admin/api/lab-order/order/list/patient?page=0&size=11").then(response=>response.data)
	commit('SET_ORDER',orders)
	console.log(orders)
}
export async function getOrderDetails({commit}, orderId){
	let orderTests = await http.get(config.apiUrl + "/admin/api/lab-order/order/"+orderId)
	.then(response => {
		return response.data
	})
	commit('SET_ORDER_INFO',orderTests)
	console.log(orderTests)
	return orderTests
}
