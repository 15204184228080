import config from "../config.json"
import http from "./httpService"

export async function forgotUsername(email) {
  return http.post(config.apiUrl + "/api/v1/user/username-reminder", {
    "email": email,
  })
}

export async function forgotUsernameBirthdate(email, birthdate) {
  return http.post(config.apiUrl + "/api/v1/user/username-reminder", {
    "email": email,
    "birthdate": birthdate
  })
}

export async function triggerPasswordReset(username) {
  return http.get(config.apiUrl + "/api/v1/user/password-reset?username=" + username)
}

export async function changePassword(token, password) {
  return http.post(config.apiUrl + "/api/v1/user/password-reset", {
    "token": token,
    "password": password
  })
}