<template>
  <div id="sign-in" :style="textPrimaryStyle">
    <div class="container" v-if="!readyToAuthorize">
      <img alt="Logo" :src="logoPath" :class="[whitelabel ? 'logo' : 'luminate-logo']">
      <h4 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Sign in to your patient portal</h4>
      <input type="text" placeholder="User name" v-model="username" :class="{error: inputError}" @focus="onInputFocus">
      <input type="password" placeholder="Password" v-model="password" :class="{error: inputError}" @focus="onInputFocus">

      <ErrorMessage v-if="showError" v-bind:text="errorMessage" />

      <router-link to="/login/retrieve-password" :style="linkSecondaryStyle">Forgot user name or password?</router-link>
      <button @click="onSignInClick" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
        <LoadingAnimation :size="10" v-if="signingIn" />
        {{signingIn ? 'Signing In' : 'Sign In'}}
      </button>
      <router-link to="/oauth/create-account" :style="linkPrimaryStyle" v-if="isOauth">Create Account</router-link>
      <router-link to="/login/create-account" :style="linkPrimaryStyle" v-else>Create Account</router-link>
      <span class="spacer"></span>
      
      <a v-if="helpLink" :href="helpLink" :style="linkSecondaryStyle" target="_blank">Need help?</a>
      <router-link v-else to="/login/help" :style="linkSecondaryStyle">Need help?</router-link>  

      <span class="spacer"></span>
    </div>
    <div class="container" v-else>
      <div class="logo-row">
        <img alt="Logo" :src="logoPath" class="access-logo" v-if="whitelabel">
        <img alt="Logo" :src="logoPathSmall" class="access-logo logo-offset" v-else>
        &nbsp;
        <span class="plus">+</span>
        &nbsp;
        <img alt="Health App Logo" src="../assets/health-logo.png">
      </div>
      <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Connect {{name}} to the Health app</h2>
      <p class="text-light">Allow the Health app to access your lab results. Your health records will be periodically refreshed.</p>

      <button @click="onAllowClick" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
        <LoadingAnimation :size="10" v-if="isAuthorizing" />
        {{isAuthorizing ? 'Authorizing' : 'Allow Access'}}
      </button>

      <p class="text-dark">This app was not created by your healthcare organization. Approving this request allows this app access to your personal health information with different privacy policies than your healthcare provider. Please review their privacy statements.</p>
    </div>
  </div>
</template>

<script>
import LoadingAnimation from '../components/LoadingAnimation'
import ErrorMessage from '../components/ErrorMessage'
import {login, loginLegacy, authorizaionCode} from '../services/loginService'
import tinycolor from 'tinycolor2'
import Cookies from 'js-cookie'
import { mapActions, mapGetters } from 'vuex'
import JWT from '../utils/jwt'

export default {
  name: 'SignIn',
  components: {
    LoadingAnimation,
    ErrorMessage
  },
  data() {
    return {
      username: null,
      password: null,
      inputError: false,
      showError: false,
      errorMessage: 'The username and password do not match a record in the system',
      signingIn: false,
      isAuthorizing: false,
      readyToAuthorize: false,
      isOauth: this.$root.isOauth,
      isDat: this.$root.isDat,
      isAuthorize: this.$root.isAuthorize,
      name: this.$root.config.name,
      whitelabel: this.$root.config.whitelabel,
      logoPath: this.$root.config.logoPath,
      logoPathSmall: this.$root.config.logoPathSmall,
      helpLink: this.$root.config.helpLink,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      buttonStyle: {
        backgroundColor: tinycolor(this.$root.config.primaryColor).darken(15).toHexString()
      },
      buttonStyleWhitelabel: {
        backgroundColor: this.$root.config.primaryColor
      }
    }
  },
  computed:{
    ...mapGetters([ 'cartItemList', 'transactionID', 'isAssigned' ]),
  },
  mounted() {
    if(this.isAuthorize && this.isOauth && Cookies.get('client_id') && Cookies.get('scope') && Cookies.get('state') && Cookies.get('redirect_uri') && Cookies.get('jwt')) {
      console.log('ready to authorize')
      this.readyToAuthorize = true
    }
    window.addEventListener('keydown', this.onKeyPress)
  },
  methods: {
    ...mapActions([
     'updateLoginStatus',
     'getPatientOrder'
    ]),
    onKeyPress(event) {
      if(event.key === "Enter") {
        this.onSignInClick()
      }
    },
    onSignInClick() {
      if(this.username && this.password && !this.signingIn) {
        this.inputError = false
        this.signingIn = true
        let responsePromise = login(this.username, this.password)
        responsePromise.then((response) => {
					console.log(response.data)
          if(response.data.status === 'success') {
            this.signingIn = false
            console.log('login successful')
            
            Cookies.set('jwt', response.data.jwtToken, { expires: 1 })
            //console.log(JWT.username)
            // set up log in status
            this.updateLoginStatus(JWT.username)
            // navigate to the ouath authorize after successful login
            if(this.isAuthorize && this.isOauth && Cookies.get('client_id') && Cookies.get('scope') && Cookies.get('state') && Cookies.get('redirect_uri')) {
              // this.$router.push('/oauth/authorize')
              this.readyToAuthorize = true
            }
            if(this.isDat) {
              this.getPatientOrder()
              if(this.cartItemList.length>0){
                this.$router.push('/dat/cart')
              }else{
                this.$router.push('/dat/alverno')
              }
              
            }                       
          }
				}).catch(error => {
          this.inputError = true
          this.showError = true
          this.signingIn = false
					if(error.response.status === 504) {
            this.errorMessage = 'There was an error, please try again later'
          }
				})
        
        let legacyLoginResponsePromise = loginLegacy(this.username, this.password)
        legacyLoginResponsePromise.then((response) => {
          if (response) {
            console.log('legacy login successful')
          }
        }).catch(error => {
          this.inputError = true
          this.showError = true
          this.signingIn = false
					console.log(error)
				})
      } else {
        this.inputError = true
      }
    },
    onInputFocus() {
      this.inputError = false
      this.showError = false
    },
    onAllowClick() {
      if(Cookies.get('client_id') && Cookies.get('scope') && Cookies.get('state') && Cookies.get('redirect_uri')) {
        this.isAuthorizing = true
        let responsePromise = authorizaionCode(Cookies.get('client_id') , Cookies.get('scope') , Cookies.get('state'), Cookies.get('redirect_uri'))
        responsePromise.then((response) => {
          this.isAuthorizing = false
          console.log(response.data)
          // navigate to health app
          let healthAppURL = Cookies.get('redirect_uri') + '?state=' + Cookies.get('state') + '&code=' + response.data
          window.location.replace(healthAppURL)
        }).catch(error => {
          console.log(error.data)
          this.isAuthorizing = false
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h4 {
  margin: 0;
}

h4 {
  margin-bottom: 40px;
}

.logo-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.logo-row img {
  width: 50px;
}

.plus {
  font-size: 30px;
  font-weight: bold;
  margin:0px 20px;
}

.access-logo {
  height: 60px;
  width: auto !important;
}

.logo-offset {
  margin-top: -10px;
}

.error-message {
  margin-bottom: 20px;
}
</style>
