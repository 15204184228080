<template>
  <div id="privacy" class="container" :style="textPrimaryStyle">
    <h1 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Luminate Health System<br />PRIVACY POLICY</h1>
    <h6>This Privacy Policy was last updated on February 7, 2018</h6>

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Our Policy</h2>
    <p>Luminate Health, Inc. ("Luminate Health", "we", "us" and/or "our") offers a web-based software-as-a-service to provide tools to access, understand, and communicate patient lab test results (the "<b>Service</b>") to users of our Service ("you", "your"). This Privacy Policy sets forth Luminate Health's policy with respect to information including personally identifiable data ("<b>Personal Data</b>") and other information that is collected from users through the Service.</p>
    <p>This privacy policy is designed to inform you about how Luminate Health gathers and uses Personal Data collected by us in connection with the Service. We will take reasonable steps to protect user privacy consistent with the guidelines set forth in this policy and with applicable U.S. laws. In this policy, "user" or "you" means any person using or otherwise benefiting from the Service, registering with us by creating an account, or participating in any other activities available through the Service. This Privacy Policy (this "Policy") is subject to the provisions our <router-link to="/login/common/terms" :style="linkPrimaryStyle">Terms of Service</router-link>, which are incorporated by reference.</p>
    <p>BY ACCESSING OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THIS PRIVACY POLICY AND OUR TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Information We Collect</h2>
    <p>When you use and interact with us through the Service, we may collect Personal Data and other information from you, as further described below:</p>
    <p><b>Personal Data That You Provide Through the Service:</b> We collect Personal Information from you when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, register for access to the Service or use the Service. Wherever Luminate Health collects Personal Information we make an effort to provide a link to this Policy.</p>
    <p><b>Health Information:</b> We provide services to healthcare providers. For example, we contract with labs to make lab test results available to patients and their healthcare providers. In providing services to healthcare providers, we receive patient health information, including information pertaining to current and prior treatment. Your health information includes Personal Data that is subject to this Policy.</p>
    <p><b>By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Policy. If you provide Personal Data to this Service, you acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of Luminate Health and the authorized third parties referred to herein located in the United States.</b></p>
    <p><b>Other Information:</b></p>
    <p><b>Non-Identifiable Data:</b> When you interact with Luminate Health through the Service, we receive and store certain personally non-identifiable information. Such information, which is collected passively using various technologies, cannot presently be used to specifically identify you. Luminate Health may store such information itself or such information may be included in databases owned and maintained by Luminate Health affiliates, agents or service providers. The Service may use such information and pool it with other information to track, for example, the total number of visitors to the Service, the number of visitors to each page of the Service, and the domain names of our visitors' Internet service providers. It is important to note that no Personal Data is available or used in this process.</p>
    <p>In operating the Service, we may use a technology called "cookies." A cookie is a piece of information that the computer that hosts the Service gives to your browser when you access the Service. Our cookies help provide additional functionality to the Service and help us analyze Service usage more accurately. For instance, the Service may set a cookie on your browser that allows you to access the Service without needing to remember and then enter a password more than once during a visit to the Service. In all cases in which we use cookies, we will not collect Personal Data except with your permission. On most web browsers, you will find a "help" section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Service's features.</p>
    <p><b>Aggregated Personal Information:</b> In an ongoing effort to better understand and serve the users of the our Service, Luminate Health often conducts research on its customer demographics, interests and behavior based on the Personal Data and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and Luminate Health may share this aggregate data with its affiliates, agents and business partners. This aggregate information does not identify you personally. Luminate Health may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Our Use of Your Personal Data and Other Information</h2>
    <p>Luminate Health uses the Personal Data that we receive from you and our customers in a manner that is consistent with this Policy. We use your health information results to make the results available to you and your healthcare providers. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by e-mail, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Service, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. Luminate Health and its subsidiaries and affiliates (the "Luminate Health Related Companies") may also use your Personal Data and other personally non-identifiable information collected through the Service to help us improve the content and functionality of the Service, to better understand our users and to improve the Service.</p>
    <p>Luminate Health and its affiliates may use your Personal Information to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each communication we send you will contain instructions permitting you to "opt-out" of receiving future communications. In addition, if at any time you wish not to receive any future communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.</p>
    <p>If Luminate Health intends on using any Personal Data in any manner that is not consistent with this Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Our Disclosure of Your Personal Data and Other Information</h2>
    <p>Luminate Health is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:</p>
    <p><b>Treatment and Healthcare Operations:</b> We provide your healthcare providers with access to your health information for treatment and healthcare operations as permitted under applicable law. For example, on behalf of lab customers, we make the results of current and prior lab tests available to your healthcare providers for treatment.</p>
    <p><b>Business Transfers:</b> As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</p>
    <p><b>Related Companies:</b> We may also share your Personal Data with our Related Companies for purposes consistent with this Policy.</p>
    <p><b>Agents, Consultants and Related Third Parties:</b> Luminate Health, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and processing payments. When we employ another company to perform a function of this nature, we only provide them with the information that they need to perform their specific function.</p>
    <p><b>Legal Requirements:</b> Luminate Health may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Luminate Health, (iii) act in urgent circumstances to protect the personal safety of users of the Service or the public, or (iv) protect against legal liability.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Children</h2>
    <p>Company does not knowingly collect Personal Data from children under the age of 13. If you are under the age of 13, please do not submit any Personal Data through the Service. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to Luminate Health through the Service, please contact us, and we will endeavor to delete that information from our databases.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Links to Other Web Sites</h2>
    <p>This Privacy Policy applies only to the Service. This Service may contain links to other websites not operated or controlled by Luminate Health (the "Third Party Sites"). The policies and procedures we described here do not apply to the Third Party Sites. Users should be aware of this when they leave our Service and review the privacy statements of each Third Party Site. This Privacy Policy applies solely to information collected by the Service.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Social Media Features and Widgets</h2>
    <p>The Service includes social media features ("Features"), such as the Facebook Like button and widgets or interactive mini-programs that run on the Service ("Widgets"). These Features and Widgets may collect your IP address, which page you are visiting on the Service, and may set a cookie to enable the Feature or Widget to function properly. Social media Features and Widgets are either hosted by a third party or hosted directly on the Service. Your interactions with these Features are governed by the privacy policy of the company providing it.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Security</h2>
    <p>Luminate Health takes reasonable steps to protect the Personal Data provided via the Service from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is ever fully secure or error free. In particular, e-mail sent to or from the Service may not be secure. Therefore, you should take special care in deciding what information you send to us via e-mail. Please keep this in mind when disclosing any Personal Data to Luminate Health via the Internet.</p>
    <p>In addition, we limit Luminate Health's employees and contractors' access to Personal Data. Only those employees and contractors with a business reason to know have access to this information. We educate our employees about the importance of maintaining confidentiality of your information.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Other Terms and Conditions</h2>
    <p>Your access to and use of the Service is subject to the <router-link to="/login/common/terms" :style="linkPrimaryStyle">Terms of Service</router-link>.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Changes to Our Privacy Policy</h2>
    <p>Luminate Health reserves the right to update or modify this Policy at any time and from time to time without prior notice. Please review this Policy periodically, and especially before you provide any Personal Data. This Policy was last updated on the date indicated above. Your continued use of the Service after any changes or revisions to this Policy shall indicate your agreement with the terms of such revised Policy.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Access to Information; Contacting Us</h2>
    <p>To keep your Personal Data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Service. You may also update your Personal Data from your profile page through your online account.</p>
    <p>Please also feel free to contact us if you have any questions about this Privacy or our information practices.</p>
    <p><b>You may contact us as follows:</b> <a href="mailto:admin@luminatehealth.com" :style="linkPrimaryStyle">admin@luminatehealth.com</a> or through your account online on the Contact Us page.</p>
    <hr :style="hrStyle">

  </div>
</template>

<script>
import tinycolor from 'tinycolor2'

export default {
  name: 'Privacy',
  data() {
    return {
      name: this.$root.config.name,
      whitelabel: this.$root.config.whitelabel,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      hrStyle: {
        borderTop: '1px solid' + tinycolor(this.$root.config.primaryColor).lighten(20).toHexString()
      }
    }
  }
}
</script>

<style scoped>
  h2, p, hr {
    width: 90%;
    max-width: 960px;
    text-align: left;
  }

  h6 {
    margin: 0;
  }

  hr {
    border: none;
    border-top: 1px solid #83b6e2;
  }

  a {
   text-decoration: underline; 
  }
</style>