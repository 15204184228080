const state = {
	isLoggedIn: false,
	user: null,
}

const mutations = {
    'SET_LOGIN' ( state, loginStatus ) {
        state.user = loginStatus;
		state.isLoggedIn = true;
		
		
    }
}

const actions = {

}

const getters = {
	isLoggedIn: (state) => {
		return state.isLoggedIn;
	},
	currentUser: (state) => {
		//console.log(state.user)
		return state.user
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}