let jwt = '';
let encodedJwt = '';

if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_JWT) {
    encodedJwt = process.env.VUE_APP_JWT;
} else if (document.cookie.includes(';')) {
    const splits = document.cookie.split(';');
    for (let i=0; i<splits.length; i++) {
        if (splits[i].trim().startsWith('jwt=')) {
            encodedJwt = splits[i].trim().split("=")[1];
        }
    }
} else {
    encodedJwt = document.cookie.split('=')[1];
}
if (encodedJwt === 'INVALID') {
    encodedJwt = ''
} else if (encodedJwt) {
    jwt = JSON.parse(atob(encodedJwt.split('.')[1]));
}

export default {
    audience: jwt.audience,
    created: jwt.created,
    encodedJwt: encodedJwt,
    exp: jwt.exp,
    labId: jwt.labId,
    roles: jwt.roles,
    username: jwt.username
}
