<template>
<transition name="modal-fade">
  <div class="modal-backdrop">
    <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
      <header class="modal-header" id="modalTitle">
        <slot name="header">
          Reason for contact
        </slot>
        <btn class="corner-btn" btnColor="btn-small btn-info " @click="close">X</btn>
      </header>

      <section class="modal-body" id="modalDescription">
        <slot name="body">
          <btn btnColor="btn btn-info">Cancel order</btn>
          
        </slot>
        <slot name="body"><btn btnColor="btn btn-info">Q&A</btn></slot>
        <btn btnColor="btn btn-info" type="button" @click="goRefund">Get refund </btn>
       </section>
       
      <footer class="modal-footer">
        <slot name="footer">
          
        </slot>
        
        <button type="button" class="btn-green" @click="close" aria-label="Close modal">
          Close
        </button>
      </footer>
    </div>
  </div>
  </transition>
</template>


<script>
import {  mapActions } from 'vuex';
import btn from '../Button.vue'
export default {
    name: 'Modal',
    props:['order'],
    components: {btn},
    methods: {
        ...mapActions([ 'refund' ]),
      close() {
        this.$emit('close');
        console.log(this.order)
      },
      goRefund(){
        this.$router.push('/dat/refund/'+this.order.id) 
      },
    },
}
</script>

<style lang="scss" scoped>
 .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 800px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: block;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    display:block;
    margin-left: 0px;
    margin-right:500px;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
  }
  .btn-info{
    color:black;
  }
  .btn-close {
    position: absolute;
    top: 0px;
    
    right: 100px;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .corner-btn{
    padding: 0.5rem;
    margin-left:10px;
  }
</style>