<template>
  <div id="create" class="container" :style="textPrimaryStyle" v-if="!userInfoComplete">
    <img alt="Logo" :src="logoPath" :class="[whitelabel ? 'logo' : 'luminate-logo']">
    <h4 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Create Account</h4>
    <span class="helper">First Name<br />(Please enter the name you use for medical records)</span>
    <input type="text" placeholder="Enter first name" id="firstname-field" v-model="firstName" @focus="onInputFocus">

    <span class="helper">Middle Name (Optional)</span>
    <input type="text" placeholder="Enter middle name" id="middlename-field" v-model="middleName" @focus="onInputFocus">

    <span class="helper">Last Name</span>
    <input type="text" placeholder="Enter last name" id="lastname-field" v-model="lastName" @focus="onInputFocus">

    <span class="helper">Date of Birth (MM / DD / YYYY)</span>
    <div class="row-full">
      <input type="number" placeholder="MM" min="0" max="12" id="month-field" @input="onDateChange" v-model="month" @focus="onInputFocus" @blur="onDateBlur">
      <input type="number" placeholder="DD" min="0" max="31" id="day-field" @input="onDateChange" v-model="day" @focus="onInputFocus" @blur="onDateBlur">
      <input type="number" placeholder="YYYY" min="0" max="9999" id="year-field" @input="onDateChange" v-model="year" @focus="onInputFocus" @blur="onDateBlur">
    </div>

    <div id="guardian-panel" class="animate-fade" v-if="isGuardian" :style="[whitelabel ? guardianBackgroundStyleWhitelabel : guardianBackgroundStyle]">
      <div class="row font-10 m-b-10">Luminate Health is available for users over the age of 18. If you are a parent or guardian who would like access to your child's records, please check the box and fill out the fields below.</div>

      <div class="row m-b-10">
        <input type="checkbox" id="guardian-check" name="guardian-check" v-model="guardianCertify" @focus="onInputFocus">
        <label for="guardian-check" class="font-10">I am a Parent/Guardian requesting access on behalf of my child</label>
      </div>

      <span class="helper">Parent/Guardian First Name</span>
      <input type="text" placeholder="Enter first name" id="guardian-firstname-field" v-model="guardianFirstName" @focus="onInputFocus">

      <span class="helper">Parent/Guardian Last Name</span>
      <input type="text" placeholder="Enter last name" id="guardian-lastname-field" v-model="guardianLastName" @focus="onInputFocus">

      <span class="helper">Parent/Guardian Date of Birth (MM / DD / YYYY)</span>
      <div class="row-full">
        <input type="number" placeholder="MM" min="0" max="12" id="guardian-month-field" @input="onDateChange" v-model="guardianMonth" @focus="onInputFocus" @blur="onDateBlur">
        <input type="number" placeholder="DD" min="0" max="31" id="guardian-day-field" @input="onDateChange" v-model="guardianDay" @focus="onInputFocus" @blur="onDateBlur">
        <input type="number" placeholder="YYYY" min="0" max="9999" id="guardian-year-field" @input="onDateChange" v-model="guardianYear" @focus="onInputFocus" @blur="onDateBlur">
      </div>
    </div>

    <span class="helper">
      Email Address
      <LoadingAnimation :size="5" class="email-loader" v-if="checkingUsername && useEmailForUsername" />
    </span>
    <input type="text" placeholder="Enter email address" id="email-field" v-model="email" @focus="onInputFocus" @blur="onUsernameBlur">

    <div class="row checkbox-row">
      <input type="checkbox" id="username" name="username" v-model="useEmailForUsername">
      <label for="username">Use email for user name (recommended)</label>
    </div>

    <span class="helper animate-fade" v-if="!useEmailForUsername && !usernameInvalid">
      {{usernameChecked ? 'User Name available' : 'User Name'}}
      <LoadingAnimation :size="5" class="username-loader" v-if="checkingUsername" />
    </span>
    <span class="helper animate-fade" v-if="!useEmailForUsername && usernameInvalid">User Name is not available. Please choose another.</span>
    <input class="animate-fade" type="text" placeholder="Enter user name" id="username-field" v-if="!useEmailForUsername" v-model="username" @focus="onInputFocus" @blur="onUsernameBlur">

    <span class="helper">Password</span>
    <input type="password" placeholder="Enter password" id="password-field" v-model="password" @focus="onInputFocus" @blur="onPasswordBlur">

    <span class="helper">Confirm Password</span>
    <input type="password" placeholder="Confirm password" id="passwordconfirm-field" v-model="passwordConfirm" @focus="onInputFocus" @input="onPasswordConfirmInput">

    <div class="row checkbox-row">
      <input type="checkbox" id="agree" name="agree" v-model="termsAgreed" @focus="onInputFocus">
      <label for="agree">I agree to the <router-link to="/login/common/terms" target="_blank" :style="linkPrimaryStyle">Terms of Service</router-link></label>
    </div>

    <span v-if="displayEmailErrorMessage || displayPasswordErrorMessage || displayErrorMessage" class="error-padding"></span>

    <ErrorMessage text="Please enter a valid email" v-if="displayEmailErrorMessage" />
    <ErrorMessage text="Your password must be at least 8 characters long" v-if="displayPasswordErrorMessage" />
    <ErrorMessage text="Please ensure your password fields match exactly" v-if="displayPasswordConfirmErrorMessage" />
    <ErrorMessage v-bind:text="errorMessage" v-if="displayErrorMessage" />
    
    <button @click="onCreateClick" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
      <LoadingAnimation :size="10" v-if="creatingUser" />
      {{creatingUser ? 'Creating Account' : 'Create Account'}}
    </button>
    <router-link to="/login/login/" class="dark-light" :style="linkPrimaryStyle">Sign In</router-link>

    <span class="spacer"></span>
    <a v-if="helpLink" :href="helpLink" :style="linkSecondaryStyle" target="_blank">Need help?</a>
    <router-link v-else to="/login/help" :style="linkSecondaryStyle">Need help?</router-link>
    <span class="spacer"></span>
  </div>

  <div id="create" class="container" :style="textPrimaryStyle" v-else>
    <img alt="Logo" :src="logoPath" :class="[whitelabel ? 'logo' : 'luminate-logo']">
    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Connect to my health data</h2>
    <h6>Please complete your registration by confirming your identity and we'll check if you have results in our system. This information will also be used to match new results to your account. You will receive an email letting you know when new results are available.</h6>

    <h6 class="helper">Date of Service</h6>
    <span class="helper">A date of service is any date in the past where you had a sample taken (blood, urine, pap, or medical procedure)</span>
    <div class="row-full">
      <select name="service-month" id="service-month" v-model="serviceMonth">
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>

      <select name="service-day" id="service-day" v-model="serviceDay">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25</option>
        <option value="26">26</option>
        <option value="27">27</option>
        <option value="28">28</option>
        <option value="29">29</option>
        <option value="30">30</option>
        <option value="31">31</option>
      </select>

      <select name="service-year" id="service-year" v-model="serviceYear">
        <option v-for="year in years" v-bind:value="year" v-bind:key="year">{{year}}</option>
      </select>
    </div>

    <h6 class="helper">What is your gender</h6>
    <select name="gender" id="gender" v-model="gender" @focus="onInputFocus">
      <option value="" selected disabled hidden>Select</option>
      <option value="M">Male</option>
      <option value="F">Female</option>
      <option value="U">Undeclared</option>
    </select>

    <h6 class="helper">What are the last four digits<br />of your SSN?</h6>
    <span class="helper">(Please note: This will only be used to match you to your data) <b>(Optional)</b></span>
    <input type="number" id="ssn" placeholder="Enter the last four digits of SSN" v-model="ssn" @input="onSSNChange" @focus="onInputFocus">

    <h6 class="helper">What is your phone number?</h6>
    <select name="phone-pref" id="phone-pref" v-model="phoneType" @focus="onInputFocus">
      <option value="MOBILE">Mobile (preferred)</option>
      <option value="HOME">Home</option>
    </select>
    <input type="text" id="phone" placeholder="Enter phone number" v-model="phone" @focus="onInputFocus">

    <h6 class="helper">What is your address?</h6>
    <input type="text" id="address" placeholder="Enter street address" v-model="address" @focus="onInputFocus">
    <input type="text" id="city" placeholder="Enter city" v-model="city" @focus="onInputFocus">
    <select name="state" id="state" v-model="state" @focus="onInputFocus">
      <option value="" selected disabled hidden @focus="onInputFocus">Select State</option>
      <option value="AL">Alabama</option>
      <option value="AK">Alaska</option>
      <option value="AZ">Arizona</option>
      <option value="AR">Arkansas</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DE">Delaware</option>
      <option value="DC">District Of Columbia</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="IA">Iowa</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="ME">Maine</option>
      <option value="MD">Maryland</option>
      <option value="MA">Massachusetts</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MS">Mississippi</option>
      <option value="MO">Missouri</option>
      <option value="MT">Montana</option>
      <option value="NE">Nebraska</option>
      <option value="NV">Nevada</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NY">New York</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VT">Vermont</option>
      <option value="VA">Virginia</option>
      <option value="WA">Washington</option>
      <option value="WV">West Virginia</option>
      <option value="WI">Wisconsin</option>
      <option value="WY">Wyoming</option>
    </select>
    <input type="number" id="zip" placeholder="Enter zip code" v-model="zipCode" @focus="onInputFocus">

    <ErrorMessage text="Please enter a valid 10 digit phone number" v-if="displayPhoneErrorMessage" />
    <ErrorMessage text="Please enter a valid 5 digit zip code" v-if="displayZipErrorMessage" />
    <ErrorMessage v-bind:text="errorMessage" v-if="displayErrorMessage" />

    <button @click="onConnectClick" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
      <LoadingAnimation :size="10" v-if="creatingUser" />
      {{creatingUser ? 'Connecting' : 'Connect'}}
    </button>

    <span class="spacer"></span>
    <a v-if="helpLink" :href="helpLink" :style="linkSecondaryStyle" target="_blank">Need help?</a>
    <router-link v-else to="/login/help" :style="linkSecondaryStyle">Need help?</router-link>
    <span class="spacer"></span>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import {checkUsername, createAccount, checkPassword} from '../services/signupService'
// import {login} from '../services/loginService'
import LoadingAnimation from '../components/LoadingAnimation'
import ErrorMessage from '../components/ErrorMessage'
import Cookies from 'js-cookie'

export default {
  name: 'Create',
  components: {
    LoadingAnimation,
    ErrorMessage,
  },
  data() {
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      month: '',
      day: '',
      year: '',
      email: '',
      useEmailForUsername: true,
      username: '',
      checkingUsername: false,
      usernameChecked: false,
      usernameInvalid: false,
      password: '',
      passwordConfirm: '',
      guardianCertify: false,
      guardianFirstName: '',
      guardianLastName: '',
      guardianMonth: '',
      guardianDay: '',
      guardianYear: '',
      isGuardian: false,
      termsAgreed: false,
      creatingUser: false,
      displayErrorMessage: false,
      displayEmailErrorMessage: false,
      displayPasswordErrorMessage: false,
      displayPasswordConfirmErrorMessage: false,
      displayPhoneErrorMessage: false,
      displayZipErrorMessage: false,
      userInfoComplete: false,
      serviceMonth: new Date().getMonth() + 1,
      serviceDay: new Date().getDate(),
      serviceYear: new Date().getFullYear(),
      gender: '',
      ssn: '',
      phoneType: 'MOBILE',
      phone: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      errorMessage: 'Please complete all required fields',
      isOauth: this.$root.isOauth,
      logoPath: this.$root.config.logoPath,
      whitelabel: this.$root.config.whitelabel,
      textColor: this.$root.config.textColorPrimary,
      helpLink: this.$root.config.helpLink,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      buttonStyle: {
        backgroundColor: tinycolor(this.$root.config.primaryColor).darken(15).toHexString()
      },
      buttonStyleWhitelabel: {
        backgroundColor: this.$root.config.primaryColor
      },
      guardianBackgroundStyle: {
        backgroundColor: tinycolor(this.$root.config.primaryColor).darken(10).toHexString()
      },
      guardianBackgroundStyleWhitelabel: {
        backgroundColor: tinycolor(this.$root.config.primaryColor).lighten(40).toHexString()
      },
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      let yearArray = []
      for (let i = 0; i < 10; i++) {
        yearArray.push(year - i)
      }

      return yearArray
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyPress)
  },
  methods: {
    onKeyPress(event) {
      if(event.key === "Enter") {
        if(this.userInfoComplete) {
          this.onConnectClick()
        } else {
          this.onCreateClick()
        }
      }
    },
    onCreateClick() {
      this.displayErrorMessage = false
      this.displayEmailErrorMessage = false
      this.displayPasswordErrorMessage = false

      if(this.validateMainForm() && !this.creatingUser) {
        this.creatingUser = true
        this.displayErrorMessage = false

        let responsePromise = checkPassword(this.password)
        responsePromise.then((response) => {
          console.log(response.data)
          this.userInfoComplete = true
          this.creatingUser = false
        }).catch(error => {
          console.log(error.response.data)
          this.creatingUser = false
          this.errorMessage = 'This password is a commonly used password. Please choose a different password'
          this.displayErrorMessage = true
        })
      } else {
        this.errorMessage = 'Please complete all required fields'
        this.displayErrorMessage = true
      }
    },
    onConnectClick() {
      if(this.validateConnectForm() && !this.creatingUser) {
        this.creatingUser = true
        this.displayErrorMessage = false

        let userInfo = {}
        userInfo.firstName = this.firstName
        if(this.middleName.length > 0) {
          userInfo.middleName = this.middleName
        }
        userInfo.lastName = this.lastName
        userInfo.birthdate = this.year + '-' + this.month + '-' + this.day
        userInfo.email = this.email
        if(this.useEmailForUsername) {
          userInfo.username = this.email
        } else {
          userInfo.username = this.username
        }
        userInfo.password = this.password
        userInfo.termsAgreed = this.termsAgreed

        if(this.isGuardian) {
          userInfo.guardianRequest = this.isGuardian
          userInfo.guardianFirstName = this.guardianFirstName
          userInfo.guardianLastName = this.guardianLastName
          userInfo.guardianBirthdate = this.guardianYear + '-' + this.guardianMonth + '-' + this.guardianDay
        }

        let newServiceMonth
        if(this.serviceMonth.toString().length === 1) {
          newServiceMonth = '0' + this.serviceMonth
        } else {
          newServiceMonth = this.serviceMonth
        }

        let newServiceDay
        if(this.serviceDay.toString().length === 1) {
          newServiceDay = '0' + this.serviceDay
        } else {
          newServiceDay = this.serviceDay
        }

        userInfo.dateOfService = this.serviceYear + '-' + newServiceMonth + '-' + newServiceDay
        userInfo.gender = this.gender
        if(this.ssn.length >= 4) {
          userInfo.ssn4 = this.ssn
        }
        userInfo.phoneType = this.phoneType
        userInfo.phone = this.phone
        userInfo.address = this.address
        userInfo.city = this.city
        userInfo.state = this.state
        userInfo.zipCode = this.zipCode

        let responsePromise = createAccount(userInfo)
        responsePromise.then((response) => {
          console.log(response.data)
          Cookies.set('jwt', response.data.jwtToken, { expires: 1 })
          if(this.isOauth) {
            this.$router.push('/oauth/authorize')
          } else {
            console.log('user has been created')
          }
        }).catch(error => {
          console.log(error.response.data)
          this.userInfoComplete = false
          this.creatingUser = false

          if(error.response.data.error_description === 'INVALID_USERNAME') {
            this.displayErrorMessage = true
            
            setTimeout(() => {
              if(this.useEmailForUsername) {
                this.$el.querySelector('#email-field').classList.add('error')
                this.errorMessage = 'This email address is unavailable to use as a user name. Please use another or choose a unique user name.'
              } else {
                this.$el.querySelector('#username-field').classList.add('error')
                this.errorMessage = 'This user name is unavailable. Please choose another.'
              }
            }, 100)
          } else if(error.response.data.error_description === 'INVALID_PASSWORD') {
            this.displayErrorMessage = true

            setTimeout(() => {
              this.$el.querySelector('#password-field').classList.add('error')
              this.$el.querySelector('#passwordconfirm-field').classList.add('error')
              this.errorMessage = 'This password is used commonly. Please choose a different password.'
            }, 100)
          }
        })
      } else {
        this.errorMessage = 'Please complete all required fields'
        this.displayErrorMessage = true
      }
    },
    validateConnectForm() {
      let isValid = true

      if(this.gender.length > 0) {
        this.$el.querySelector('#gender').classList.remove('error')
      } else {
        this.$el.querySelector('#gender').classList.add('error')
        isValid = false
      }

      // if(this.ssn.length > 3) {
      //   this.$el.querySelector('#ssn').classList.remove('error')
      // } else {
      //   this.$el.querySelector('#ssn').classList.add('error')
      //   isValid = false
      // }

      if(this.phoneType.length > 0) {
        this.$el.querySelector('#phone-pref').classList.remove('error')
      } else {
        this.$el.querySelector('#phone-pref').classList.add('error')
        isValid = false
      }

      if(this.phone.length > 9) {
        this.$el.querySelector('#phone').classList.remove('error')
      } else if(this.phone.length === 0) {
        this.$el.querySelector('#phone').classList.add('error')
        isValid = false
      } else {
        this.$el.querySelector('#phone').classList.add('error')
        this.displayPhoneErrorMessage = true
        isValid = false
      }

      if(this.address.length > 0) {
        this.$el.querySelector('#address').classList.remove('error')
      } else {
        this.$el.querySelector('#address').classList.add('error')
        isValid = false
      }

      if(this.city.length > 0) {
        this.$el.querySelector('#city').classList.remove('error')
      } else {
        this.$el.querySelector('#city').classList.add('error')
        isValid = false
      }

      if(this.state.length > 0) {
        this.$el.querySelector('#state').classList.remove('error')
      } else {
        this.$el.querySelector('#state').classList.add('error')
        isValid = false
      }

      if(this.zipCode.length > 4) {
        this.$el.querySelector('#zip').classList.remove('error')
      } else if(this.zipCode.length === 0) {
        this.$el.querySelector('#zip').classList.add('error')
        isValid = false
      } else {
        this.$el.querySelector('#zip').classList.add('error')
        this.displayZipErrorMessage = true
        isValid = false
      }

      return isValid
    },
    validateMainForm() {
      let isValid = true

      if(this.firstName.length > 0) {
        this.$el.querySelector('#firstname-field').classList.remove('error')
      } else {
        this.$el.querySelector('#firstname-field').classList.add('error')
        isValid = false
      }

      if(this.lastName.length > 0) {
        this.$el.querySelector('#lastname-field').classList.remove('error')
      } else {
        this.$el.querySelector('#lastname-field').classList.add('error')
        isValid = false
      }

      if(this.month.toString().length === this.$el.querySelector('#month-field').placeholder.length) {
        this.$el.querySelector('#month-field').classList.remove('error')
      } else {
        this.$el.querySelector('#month-field').classList.add('error')
        isValid = false
      }

      if(this.day.toString().length === this.$el.querySelector('#day-field').placeholder.length) {
        this.$el.querySelector('#day-field').classList.remove('error')
      } else {
        this.$el.querySelector('#day-field').classList.add('error')
        isValid = false
      }

      if(this.year.toString().length === this.$el.querySelector('#year-field').placeholder.length) {
        this.$el.querySelector('#year-field').classList.remove('error')
      } else if(this.year.length > this.$el.querySelector('#year-field').placeholder.length) {
        this.year = this.year.slice(0, this.$el.querySelector('#year-field').placeholder.length)
      } else {
        this.$el.querySelector('#year-field').classList.add('error')
        isValid = false
      }

      // test guardian fields

      if(this.isGuardian) {
        if(this.guardianFirstName.length > 0) {
          this.$el.querySelector('#guardian-firstname-field').classList.remove('error')
        } else {
          this.$el.querySelector('#guardian-firstname-field').classList.add('error')
          isValid = false
        }

        if(this.guardianLastName.length > 0) {
          this.$el.querySelector('#guardian-lastname-field').classList.remove('error')
        } else {
          this.$el.querySelector('#guardian-lastname-field').classList.add('error')
          isValid = false
        }

        if(this.guardianMonth.length === this.$el.querySelector('#guardian-month-field').placeholder.length) {
          this.$el.querySelector('#guardian-month-field').classList.remove('error')
        } else {
          this.$el.querySelector('#guardian-month-field').classList.add('error')
          isValid = false
        }

        if(this.guardianDay.length === this.$el.querySelector('#guardian-day-field').placeholder.length) {
          this.$el.querySelector('#guardian-day-field').classList.remove('error')
        } else {
          this.$el.querySelector('#guardian-day-field').classList.add('error')
          isValid = false
        }

        if(this.guardianYear.length === this.$el.querySelector('#guardian-year-field').placeholder.length) {
          this.$el.querySelector('#guardian-year-field').classList.remove('error')
        } else if(this.guardianYear.length > this.$el.querySelector('#guardian-year-field').placeholder.length) {
          this.guardianYear = this.guardianYear.slice(0, this.$el.querySelector('#guardian-year-field').placeholder.length)
        } else {
          this.$el.querySelector('#guardian-year-field').classList.add('error')
          isValid = false
        }

        if(this.guardianCertify) {
          this.$el.querySelector('#guardian-check').classList.remove('error')
        } else {
          this.$el.querySelector('#guardian-check').classList.add('error')
          isValid = false
        }
      }

      // end guardian section

      if(this.validateEmail(this.email)) {
        this.$el.querySelector('#email-field').classList.remove('error')
      } else if(this.email.length === 0) {
        this.$el.querySelector('#email-field').classList.add('error')
        isValid = false
      } else {
        this.$el.querySelector('#email-field').classList.add('error')
        isValid = false
        this.displayEmailErrorMessage = true
      }

      if(!this.useEmailForUsername) {
        if(this.usernameChecked && this.usernameInvalid || !this.usernameChecked) {
          this.$el.querySelector('#username-field').classList.add('error')
          isValid = false
        } else {
          this.$el.querySelector('#username-field').classList.remove('error')
        }
      }

      if(this.password.length > 7) {
        this.$el.querySelector('#password-field').classList.remove('error')
      } else if(this.password.length === 0) {
        this.$el.querySelector('#password-field').classList.add('error')
        isValid = false
      } else {
        this.$el.querySelector('#password-field').classList.add('error')
        isValid = false
        this.displayPasswordErrorMessage = true
      }

      if(this.password.length > 7 && this.password === this.passwordConfirm) {
        this.$el.querySelector('#passwordconfirm-field').classList.remove('error')
      } else {
        this.$el.querySelector('#passwordconfirm-field').classList.add('error')
        isValid = false
      }

      if(this.termsAgreed) {
        this.$el.querySelector('#agree').classList.remove('error')
      } else {
        this.$el.querySelector('#agree').classList.add('error')
        isValid = false
      }
      
      return isValid
    },
    onSSNChange(e) {
      if(e.target.value.length > 4) {
        e.target.value = e.target.value.slice(0, 4)
        this.ssn = e.target.value.slice(0, 4)
      }
    },
    onInputFocus(e) {
      this.displayErrorMessage = false
      this.displayEmailErrorMessage = false
      this.displayPasswordErrorMessage = false
      this.displayPasswordConfirmErrorMessage = false
      this.displayPhoneErrorMessage = false
      this.displayZipErrorMessage = false
      e.target.classList.remove('error')
    },
    onPasswordBlur(e) {
      if(e.target.value !== this.passwordConfirm && this.passwordConfirm.length > 0) {
        this.displayPasswordConfirmErrorMessage = true
        this.$el.querySelector('#passwordconfirm-field').classList.add('error')
      } else {
        this.displayPasswordConfirmErrorMessage = false
        this.$el.querySelector('#passwordconfirm-field').classList.remove('error')
      }
    },
    onPasswordConfirmInput(e) {
      if(e.target.value !== this.password) {
        this.displayPasswordConfirmErrorMessage = true
        this.$el.querySelector('#passwordconfirm-field').classList.add('error')
      } else {
        this.displayPasswordConfirmErrorMessage = false
        this.$el.querySelector('#passwordconfirm-field').classList.remove('error')
      }
    },
    onUsernameBlur() {
      if(this.useEmailForUsername) {
        if(this.validateEmail(this.email)) {
          this.$el.querySelector('#email-field').classList.remove('error')
          this.username = this.email
          this.checkingUsername = true
          let responsePromise = checkUsername(this.username)
          responsePromise.then((response) => {
            if(response.status === 200) {
              this.usernameChecked = true
              this.checkingUsername = false
            }
          }).catch(error => {
            if(error.response.status === 400) {
              this.useEmailForUsername = false
              this.usernameInvalid = true
              this.checkingUsername = false

              setTimeout(() => {
                this.$el.querySelector('#username-field').classList.add('error')
              }, 100)
            }
          })
        } else {
          this.$el.querySelector('#email-field').classList.add('error')
        }
      } else {
        this.usernameInvalid = false
        this.usernameChecked = false
        this.$el.querySelector('#username-field').classList.remove('error')
        this.$el.querySelector('#username-field').classList.remove('success')

        if(this.username.length > 0) {
          this.checkingUsername = true
          let responsePromise = checkUsername(this.username)
          responsePromise.then((response) => {
            if(response.status === 200) {
              this.$el.querySelector('#username-field').classList.add('success')
              this.usernameChecked = true
              this.checkingUsername = false
            }
          }).catch(error => {
            if(error.response.status === 400) {
              this.$el.querySelector('#username-field').classList.add('error')
              this.usernameInvalid = true
              this.checkingUsername = false
            }
          })
        }
      }   
    },
    onDateChange(e) {
      let monthField = this.$el.querySelector('#month-field')
      let dayField = this.$el.querySelector('#day-field')
      let yearField = this.$el.querySelector('#year-field')
      // let guardianMonthField = this.$el.querySelector('#guardian-month-field')
      let guardianDayField = this.$el.querySelector('#guardian-day-field')
      let guardianYearField = this.$el.querySelector('#guardian-year-field')

      if(e.target.value.length > e.target.placeholder.length - 1) {
        switch(e.target.id) {
          case 'month-field':
            if(this.month > 12) {
              this.month = 12
            }
            dayField.focus()
            break

          case 'day-field':
            if(this.day > 31) {
              this.day = 31
            }
            yearField.focus()
            break

          case 'year-field':
            if(this.year > new Date().getFullYear()) {
              this.year = new Date().getFullYear()
            }
            break

          case 'guardian-month-field':
            guardianDayField.focus()
            break

          case 'guardian-day-field':
            guardianYearField.focus()
            break

          case 'guardian-year-field':
            if(this.guardianYear > new Date().getFullYear()) {
              this.guardianYear = new Date().getFullYear()
            }
            break
        }
      }

      if(monthField.value.length > 0 && dayField.value.length > 0 && yearField.value.length === yearField.placeholder.length) {
        let age = this.calculateAge(new Date(this.year, this.month, this.day))

        if(age < 18) {
          this.isGuardian = true
        } else {
          this.isGuardian = false
        }
      }

      if(e.target.value.length > e.target.placeholder.length) {
        e.target.value = e.target.value.slice(0, e.target.placeholder.length)
      }
    },
    onDateBlur(e) {
      switch(e.target.id) {
        case 'month-field':
          if(this.month.toString().length === 1) {
            this.month = 0 + this.month
          }
          break

        case 'day-field':
          if(this.day.toString().length === 1) {
            this.day = 0 + this.day
          }
          break

        case 'guardian-month-field':
          if(this.guardianMonth.toString().length === 1) {
            this.guardianMonth = 0 + this.guardianMonth
          }
          break

        case 'guardian-day-field':
          if(this.guardianDay.toString().length === 1) {
            this.guardianDay = 0 + this.guardianDay
          }
          break
      }
    },
    calculateAge(dob) {
      let diff_ms = Date.now() - dob.getTime()
      let age_dt = new Date(diff_ms)
  
      return Math.abs(age_dt.getUTCFullYear() - 1970)
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return re.test(String(email).toLowerCase())
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2, h6, h4 {
  margin: 0;
}

h4 {
  margin-bottom: 40px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h6 {
  margin-bottom: 30px;
  max-width: 300px;
  text-align: left;
}

h6.helper {
  font-size: 16px;
  margin-bottom: 10px;
}

.helper {
  position: relative;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left;
  width: 300px;
}

.row {
  text-align: left;
  width: 300px;
}

#guardian-panel {
  display: flex;
  flex-direction: column;
  background-color: #1e7ad1;
  padding: 20px 20px 10px 20px;
  margin: 10px 0;
  border-radius: 10px;
}

.animate-fade {
  animation: fadeIn 0.3s forwards;
  animation-timing-function: ease-out;
  transform-origin: top;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.font-10 {
  font-size: 10px;
}

#guardian-panel input {
  width: 100%;
}

.hidden-row {
  text-align: left;
  width: 300px;
}

#month-field, #day-field, #guardian-month-field, #guardian-day-field {
  width: 80px !important;
  margin-right: 10px;
}

#year-field, #guardian-year-field {
 width: 115px !important;
}

label {
  font-size: 12px;
  vertical-align: 2px;
}

label a {
  text-decoration: underline;
}

#username, #agree, #guardian-check {
  width: 15px !important;
  margin-left: -2px;
  margin-right: 5px;
}

.username-loader {
  position: absolute;
  top: 6px;
  margin-left: 50px;
}

.email-loader {
  position: absolute;
  top: 6px;
  margin-left: 65px;
}

#service-month {
  width: 120px;
}

#service-day {
  width: 70px;
}

#service-year {
  width: 90px;
}

.row-full {
  text-align: left;
  width: 300px;
  display: flex;
  justify-content: space-between;
}

h6.helper {
  font-size: 16px;
  margin-bottom: 10px;
}

.error-padding {
  display: block;
  height: 12px;
  width: 100%;
}

input[type=checkbox] {
  padding: 2px;
}
</style>
