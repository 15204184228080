<template>
  <div id="retrieve" class="container" :style="textPrimaryStyle">
    <img alt="Logo" :src="logoPath" :class="[whitelabel ? 'logo' : 'luminate-logo']">
    <h4 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">{{successPassword || successUsername ? 'Check Your Email' : 'Retrieve Username or Password'}}</h4>

    <div class="container animate-fade" v-if="successPassword">
      <p>If our database contains a record that matches the information you provided we will send you an email to the address we have on file.</p>
      <p>Please check your email and look for a message with the subject <b>Reset Password Link</b>, for a link to reset your password.</p>
      <p>If you do not receive an email, you may not have signed up for an account yet, <router-link to="/login/create-account" :style="linkPrimaryStyle">Sign Up here</router-link>.</p>
    </div>
    
    <div class="container animate-fade" v-else-if="successUsername">
      <p>If our database contains a record that matches the information you provided we will send you an email to the address we have on file.</p>
      <p>Please check your email with the subject <b>Username Reminder</b>, it will contain your username.</p>
      <p>If you do not receive an email, you may not have signed up for an account yet, <router-link to="/login/create-account" :style="linkPrimaryStyle">Sign Up here</router-link>.</p>
    </div>

    <div class="container" v-else>
      <h2>Forgot Password?</h2>
      <span class="helper">Enter your username below and we'll send you password reset instructions.</span>
      <input type="text" placeholder="Enter Username" v-model="username" id="username-field" @focus="onInputFocus">

      <button @click="onPasswordSubmit" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
        <LoadingAnimation :size="10" v-if="retrievingPassword" />
        {{retrievingPassword ? 'Submitting' : 'Submit'}}
      </button>

      <hr :style="hrStyle">

      <h2>Forgot Username?</h2>
      <span class="helper">Enter the e-mail for your account below and we'll send you your username.</span>
      <input type="text" placeholder="Enter Email" v-model="email" id="email-field" @focus="onInputFocus">

      <span class="helper animate-fade" v-if="dobNeeded">Enter Date of Birth and submit again.</span>
      <div class="row animate-fade" v-if="dobNeeded">
        <input type="number" placeholder="MM" min="0" max="12" id="month-field" @input="onDateChange" v-model="month">
        <input type="number" placeholder="DD" min="0" max="31" id="day-field" @input="onDateChange" v-model="day">
        <input type="number" placeholder="YYYY" min="0" max="9999" id="year-field" @input="onDateChange" v-model="year">
      </div>

      <ErrorMessage v-if="emailInvalid" text="Please enter a valid email" />

      <button @click="onUsernameSubmit" :style="[whitelabel ? buttonStyleWhitelabel : buttonStyle]">
        <LoadingAnimation :size="10" v-if="retrievingUsername" />
        {{retrievingUsername ? 'Submitting' : 'Submit'}}
      </button>
    </div>
  </div>
</template>

<script>
import {forgotUsername, forgotUsernameBirthdate, triggerPasswordReset} from '../services/retrievalServices'
import LoadingAnimation from '../components/LoadingAnimation'
import ErrorMessage from '../components/ErrorMessage'
import tinycolor from 'tinycolor2'

export default {
  name: 'Retrieve',
  components: {
    LoadingAnimation,
    ErrorMessage
  },
  data() {
    return {
      email: '',
      dob: '',
      month: '',
      day: '',
      year: '',
      username: '',
      dobNeeded: false,
      emailInvalid: false,
      retrievingUsername: false,
      retrievingPassword: false,
      successUsername: false,
      successPassword: false,
      logoPath: this.$root.config.logoPath,
      name: this.$root.config.name,
      whitelabel: this.$root.config.whitelabel,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkPrimaryStyle: {
        color: this.$root.config.textColorPrimary,
        textDecoration: 'underline'
      },
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      hrStyle: {
        borderTop: '1px solid' + tinycolor(this.$root.config.primaryColor).lighten(20).toHexString()
      },
      buttonStyle: {
        margin: '10px 0 20px 0',
        backgroundColor: tinycolor(this.$root.config.primaryColor).darken(15).toHexString()
      },
      buttonStyleWhitelabel: {
        marginTop: '10px 0 20px 0',
        backgroundColor: this.$root.config.primaryColor
      }
    }
  },
  methods: {
    onPasswordSubmit() {
      if(this.username.length > 1) {
        this.retrievingPassword = true
        let responsePromise = triggerPasswordReset(this.username)
        responsePromise.then((response) => {
          if(response.data.status === 'success') {
            this.successPassword = true
          }
          this.retrievingPassword = false
        }).catch(error => {
          console.log(error)
          this.retrievingPassword = false
          this.successPassword = true
        })
      } else {
        this.$el.querySelector('#username-field').classList.add('error')
      }
    },
    onUsernameSubmit() {
      this.emailInvalid = false
      
      if(this.dobNeeded) {
        if(this.validateEmail(this.email)) {
          if(this.dob.length === 10) {
            this.retrievingUsername = true
            let responsePromise = forgotUsernameBirthdate(this.email, this.dob)
            responsePromise.then((response) => {
              this.retrievingUsername = false
              if(response.status === 200) {
                this.successUsername = true
              }
            }).catch(error => {
              console.log(error)
              this.retrievingUsername = false
            })
          } else {
            this.$el.querySelector('#year-field').classList.add('error')
            this.$el.querySelector('#month-field').classList.add('error')
            this.$el.querySelector('#day-field').classList.add('error')
          }
        } else {
          this.emailInvalid = true
          this.$el.querySelector('#email-field').classList.add('error')
        }
      } else {
        if(this.validateEmail(this.email)) {
          this.retrievingUsername = true
          let responsePromise = forgotUsername(this.email)
          responsePromise.then((response) => {
            this.retrievingUsername = false
            if(response.status === 200) {
              this.successUsername = true
            }
          }).catch(error => {
            this.retrievingUsername = false
            if(error.response.status === 409) {
              this.dobNeeded = true
            }
          })
        } else {
          this.emailInvalid = true
          this.$el.querySelector('#email-field').classList.add('error')
        }
      }
    },
    onInputFocus(e) {
      e.target.classList.remove('error')
    },
    onDateChange(e) {
      // let monthField = this.$el.querySelector('#month-field')
      let dayField = this.$el.querySelector('#day-field')
      let yearField = this.$el.querySelector('#year-field')

      if(e.target.value.length > e.target.placeholder.length - 1) {
        switch(e.target.id) {
          case 'month-field':
            dayField.focus()
            break

          case 'day-field':
            yearField.focus()
            break
        }
      }

      if(e.target.value.length > e.target.placeholder.length) {
        e.target.value = e.target.value.slice(0, e.target.placeholder.length)
      }

      this.dob = this.year + '-' + this.month + '-' + this.day
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return re.test(String(email).toLowerCase())
    },
    onKeyDown(e) {
      if(e.key === 'Enter') {
        if(this.username.length > 1) {
          this.onPasswordSubmit()
        } else if(this.email.length > 1) {
          this.onUsernameSubmit()
        }
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown)
  }
}
</script>

<style scoped>
.row {
  text-align: left;
  width: 300px;
}

.hidden-row {
  text-align: left;
  width: 300px;
}

hr {
  width: 100%;
  max-width: 300px;
  border: none;
}

.helper {
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
  width: 300px;
}

a:link {
  font-weight: bolder;
}

input {
  min-width: 300px;
}

#month-field, #day-field {
  width: 80px !important;
  min-width: auto;
  margin-right: 10px;
}

#year-field {
 width: 115px !important;
 min-width: auto;
}

.animate-fade {
  animation: fadeIn 0.3s forwards;
  animation-timing-function: ease-out;
  transform-origin: top;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

::-webkit-datetime-edit {
  font-family: sans-serif;
}
</style>