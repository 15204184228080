<template>
<div class="container">
  <h3> Refund Detail Page</h3>
  <btn btnColor="btn btn-medium"  @click="goRefund">get refund</btn>
</div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import btn from '../Button.vue';
export default {
  data(){
    return{
      order: null
    }
  },
  components:{ btn },
  methods: {
      ...mapActions([ 'refund' ]),
      goRefund(){
        console.log(this.order)
        console.log(this.order.stripePaymentIntentId)
          this.refund(this.order.stripePaymentIntentId)
          console.log('Refunded!')
      },
  },
  computed: {
      ...mapGetters([ 'orders' ]),
  },
  mounted() {
    let id = this.$route.params.id;
    console.log(this.$route.params)
    console.log(id)
    let filterArr = this.orders.filter((item) => {
                return item.id == id
            });
            if (filterArr.length > 0) {
                this.order = filterArr[0];                
            }
  }
}
</script>

<style>

</style>