import { createStore } from 'vuex';
import cart from './modules/cart';
import products from './modules/products';
import auth from './modules/auth';
import * as actions from './actions';
import messages from './modules/messages';
import order from './modules/order';

const store = createStore({
	actions,
		modules: {	
			cart,
			products,	
			auth,	
			messages,
			order
	}
  })

export default store;

