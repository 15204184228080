const state = {	
	orderNumber:'',
	orderDate:'',
	orderStatus:'',
	orderTotals:'',
	orders:null,
}

const mutations = {
    'SET_ORDER_INFO'(state,details){
        state.orderNumber=details.id,
		state.orderDate=details.updatedDateTime,
		state.orderStatus=details.status,
		state.orderTotals=details.totals
    },
	'SET_ORDER'(state,orders){
		state.orders = orders;
		console.log(state.orders);
	}

}

const actions = {

}

const getters = {
	details: (state) => {
		const details = {
			id: state.orderNumber,
			date: state.orderDate,
			status: state.orderStatus,
			total: state.orderTotals
		}
		return details
	},
	orders: (state) => {
		return state.orders.reverse();
	}

}

export default {
	state,
	mutations,
	actions,
	getters
}