const state = {	
	productList: []
}

const mutations = {
	'UPDATE_PRODUCT_LIST' (state, productList) {
		productList.forEach(function(test){
			test.price = test.price/100
			test.isAdd = false
		});
		state.productList = productList
	},
	'ADD_STATUS' (state, id) {
		state.productList.forEach(function(item){
			if(item.testId==id){
				item.isAdd=true
			}
		})
	},
	'REMOVE_STATUS' (state, id) {
		state.productList.forEach(function(item){
			if(item.testId==id){
				item.isAdd=false
			}
		})
	}
}

const actions = {

}

const getters = {
	products: (state) => {
		return state.productList;
	},
	isProductLoading: (state) => {
		return state.isLoading;
	},
}

export default {
	state,
	mutations,
	actions,
	getters
}