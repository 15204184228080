import axios from "axios"
import Cookies from 'js-cookie'

axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common["CF-Access-Client-Id"] = "f7300a35d87d37a81c5b0896e68b6d51.access"
axios.defaults.headers.common["CF-Access-Client-Secret"] = "e222e74419e673e98c8d7d69b2054d79a29306ad2f6452a3a50c6e96f6c43d28"

axios.interceptors.request.use(function (config) {
    const jwt = Cookies.get('jwt')
    if (jwt) {
        config.headers.common["x-auth-token"] = jwt
    }
    return config;
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}
