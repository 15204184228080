<template>
  <div id="container">
    <div id='nav'>
      <router-link to="/dat/alverno" id="left"><strong>Alverno</strong></router-link>
      <btn id="cart" 
        btnColor="btn btn-small btn-info" 
        :cartIcon="true"
        @click="viewCart"
      >Cart<span class="btn-circle">
              {{ numItems }}
            </span>
      </btn>
      <router-link v-if="!isLoggedIn" id="right" to="/">
            <span class="nav-line-1" >Returns </span>
            <span class="nav-line-2" >& Orders</span>
      </router-link>
      <router-link v-else id="right" @click="getOrders" to="/dat/order-history">
        <span class="nav-line-1" >Returns </span>
        <span class="nav-line-2" >& Orders</span>

      </router-link>
     

      <div>
       <div id="right">
          <span class="nav-line-1">Hello,</span>
          <span v-if="!isLoggedIn" class="nav-line-2" @click="signIn">Sign in</span>
          <span v-else class="nav-line-2">{{currentUser}}</span>
       </div>
      </div>   
    </div> 
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import btn from './Button.vue';

export default {
  components: {
    btn
  },
  computed: {
    ...mapGetters(['cartValue', 'currentUser', 'cartItemList', 'transactionID', 'isLoggedIn']),
    numItems() {
      return this.cartItemList.reduce((total, item) => {
        total += item.quantity;
        return total
      }, 0);
    },
  },
  methods: {
    ...mapActions(['logout','getShoppingCart', 'getPatientOrder']),
    viewCart(){
      this.$router.push('/dat/cart')
    },
    signIn(){
      this.$router.push('/dat/login')
    },
    getOrders(){
      this.getPatientOrder()
    }
  }
}
</script>


<style scoped lange="sass">
#nav {
  z-index: 2;
  display: block;
  min-width: 400px;
  padding: 10px;
  width: 100%;
  height: 40px;
  top: 0px;
  left: 0%;
  position: fixed;
  background-color: gray;
  line-height: 20px;
}

#left {
    float: left;
    text-align: left;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 20px;
    font-size: 22px;
}


#right {
    float: right;
    height: 0%;
    text-align: right;
    margin-right: 5px;
    padding-top: 7px;
    color: white;
    cursor: pointer;
}

#cart {
  float: right;
  height: 0%;
  padding-top: 12px;
  padding-left: 0px;
  padding-right: 50px;
  margin-top: 0%; 
  margin-right: 5px; 
}
.nav-line-1 {
  float: left;
  font-size: 13px;
  line-height: 8px;
  height: 11px;
  font-weight: 400;
  clear: both;
  display:block;
  
}
.nav-line-2 {
  clear: both;
  display: block;
  padding-right: 10px;
}
.text-center {
  text-align: center;
}

.btn-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: 20px;
    background-color: rgb(249, 250, 250);
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leave-enter-active, .leave-leave-active {
    transition: all 3.2s;
  }
  .leave-enter, .leave-leave-to {
    opacity: 0;
    transform: translateX(-50%);
  }
</style>
