<template>
  
  <router-view id="main-container" />
  <BackgroundGradient />
  <MessageComponent />
  
</template>

<script>
import BackgroundGradient from './components/BackgroundGradient'
import MessageComponent from './alvernoDat/components/MessageComponent.vue';
import themes from './themes.json'
import { mapActions } from 'vuex';
export default {
  name: 'App',
  components: {BackgroundGradient,MessageComponent},
  data() {
    return {
      isOauth: window.location.pathname.split('/')[1] == 'oauth',
      isDat: window.location.pathname.split('/')[1] == 'dat',
      isAuthorize: window.location.pathname.split('/')[2] == 'authorize',
      config: {}
    }
  },
  methods: {
    ...mapActions(['listenToProductList'])
  },
  created() {
    this.listenToProductList();

    let isPathgroup = window.location.host.startsWith('pathgroup')
    if(isPathgroup) {
      this.config = themes.pathgroup
    } else {
      this.config = themes.luminate
    }

    if(this.config.whitelabel) {
      document.body.style.backgroundColor = '#efeff4'
    } else {
      document.body.style.backgroundColor = this.config.primaryColor
    }
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 30px;
  z-index: 1;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

input, select {
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 80%;
  max-width: 300px;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: 0.2s;
}

.error {
  background-color: #ffeff0;
  box-shadow: inset 0px 0px 0px 2px #ffb3b8, 0px 0px 10px #004e97;
}

.success {
  background-color: #f5ffef;
  box-shadow: inset 0px 0px 0px 2px #dcffb3, 0px 0px 10px #71aee7;
}

button {
  position: relative;
  padding: 10px 50px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border: none;
  color: white;
  background-color: #2b60b9;
  margin-top: 40px;
  margin-bottom: 10px;
  cursor: pointer;
}

a:link, a:hover, a:active, a:visited {
  text-decoration: none;
  color: white;
}

a:link {
  text-decoration: none;
}

.m-b-10 {
  margin-bottom: 10px;
}

.spacer {
  height: 40px;
}

h4 {
  margin: 0;
}

h4 {
  margin-bottom: 40px;
}

p {
  text-align: left;
  width: 90%;
  max-width: 960px;
}

input:focus::placeholder {
  color: transparent;
}

.logo, .luminate-logo {
  max-width: 200px;
  margin-bottom: 10px;
}

.luminate-logo {
  margin-left: -30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
