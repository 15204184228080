<template>
  <div class="card">
      <h3>{{test.name}}</h3>
      <div class="container">
        <p class="description">{{description}}</p>
        <h4 class="test-detail-button" @click="showDetails">Learn More</h4>
        <div class="float-container">
          
          <h5 class="price">Price: ${{test.price}}</h5>
          
          
            <button v-if="!test.isAdd" @click="addItem">
              Add to cart
            </button>
            <button class="text-muted" v-if="test.isAdd" @click="removeItem">
              Remove from cart
            </button>
          
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';

export default {
  props: ['test'],
  computed: {
    ...mapGetters([ 'transactionID' ]),
    description() {
            if(this.test.description.length>300){
              return this.test.description.substring(0,300) + '...'
            }
            return this.test.description
            
    }
  },
  methods: {
    ...mapActions(['addTestToCart','removeTestInCart', 'assignCartToPatient']),
    addItem() {
      this.addTestToCart(this.test)
    },
    removeItem() {
			this.removeTestInCart(this.test)
		},
    showDetails() {
      this.$router.push('/dat/product/' + this.test.testId)
    }
  },
}
</script>

<style lang="scss" scoped>
  h3 {
      padding:  30px 20px 20px 20px;
      text-align: center;
      margin: 0;
      background-color: rgba(44, 58, 66, 0.8);
      height: 50px;
      border-radius: 5px 5px 0 0;
      color: white;
      font-size: 17px;
      }
  .container {
    padding: 2px 16px;
  }
  .shop-section{
    margin-bottom: 20px;

  }
    .card{
        border-radius: 8px;
        background-color: white;
        box-shadow: 5px grey;
        
        h5.price{
            color: rgb(82, 79, 79);
            font-size: 14px;
            font-weight: 100%;
            display:inline-block;
            margin-right: 50px;
        }

        p.description {
            font-size: .95rem;
            padding: 10px;
            height: 180px;
        }
       
       
        button {
           width: 180px;
           display: inline-block;
           background-color: rgb(63, 111, 173);
        }
        button:hover{
          background-color: green
        }
       
        button.text-muted {
            color: white;
            padding:10px;
            background-color: grey;
        }
        
    }
    h4.test-detail-button {
            color: rgb(46, 126, 153);
            font-weight: bold;
            font-size: 1.15rem;    
            cursor: pointer;
            margin-bottom: 0px;
        }

      h4:hover{
        color: green;
      }

    @media (min-width: 500px) {
        .card {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;

          border-radius: 5px;
          width: 380px;
          margin: 10px;
        }
        .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
    }

</style>