<template>
  <div id="terms" class="container" :style="textPrimaryStyle">
    <h1 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">Luminate Health System Terms of Service Agreement</h1>
    <h6>Last Updated February 7, 2018</h6>

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">1. Acceptance of the Terms and Conditions</h2>
    <p>1.1. Luminate Health, Inc. (herein referred to as the "<b>Luminate Health</b>," "<b>we</b>," "<b>us</b>" or "<b>our</b>") provides the information, features, and services available on or through the Luminate Health System (the "Service") to our users ("you", "your"). All use of the Service is subject to the terms and conditions contained in this Terms of Service Agreement (the "Agreement"). Please read this Agreement carefully. By accessing, browsing or otherwise using the Service, you acknowledge that you have read, understood, and agree to be bound by this Agreement. If you do not accept the terms and conditions of this Agreement, you shall not access, browse or use the Service.</p>
    <p>1.2. You understand and agree that we may change this Agreement at any time without prior notice. You may read a current, effective copy of this Agreement at any time by selecting the "Terms of Service" link on the Luminate Health System. The revised terms and conditions will become effective at the time of posting. Any use of the Service after such date shall constitute your acceptance of such revised terms and conditions. If any change to this Agreement is not acceptable to you, your sole remedy is to cease accessing, browsing and otherwise using the Service.</p>
    <p>1.3. Your access to and use of the Service is also subject to Luminate Health's System <router-link to="/login/common/privacy" :style="linkPrimaryStyle">Privacy Policy</router-link>, the terms and conditions of which are hereby incorporated herein by reference.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">2. No Medical Advice</h2>
    <p>2.1. The Service does not constitute medical or health care advice or recommendations. Any content accessed through the Service is for informational purposes only, and is not intended to cover all possible uses, directions, or precautions. This content should not be used during a medical emergency or for the diagnosis or treatment of any medical condition. Patients should consult your doctor or other qualified health care provider if you have any questions about a medical condition, or before taking any drug, changing your diet or commencing or discontinuing any course of treatment. Do not ignore or delay obtaining professional medical advice because of information accessed through the Service. Luminate Health and its agents assume no responsibility for any consequence relating directly or indirectly to any action or inaction you take based on the information, the Service or other material on the Service.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">3. Use of the Service</h2>
    <p>3.1. This Service contains material, including but not limited to software, text, graphics and images (collectively referred to as the "<b>Content</b>"). We may own the Content or portions of the Content may be made available to us through arrangements that we have with third-parties. The Content is protected by United States and foreign intellectual property laws. Unauthorized use of the Content may result in violation of copyright, trademark, and other laws. You have no rights in or to the Content, and you will not use, copy or display the Content except as permitted under this Agreement. No other use is permitted without our prior written consent. You must retain all copyright and other proprietary notices contained in the original Content on any copy you make of the Content. You may not sell, transfer, assign, license, sublicense, or modify the Content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the Content in any way for any public or commercial purpose. The use or posting of any of the Content on any other website or in a networked computer environment for any purpose is expressly prohibited. If you violate any part of this Agreement, your right to access and/or use the Content and the Service shall automatically terminate and you shall immediately destroy any copies you have made of the Content.</p>
    <p>3.2. The trademarks, service marks, and logos of Luminate Health (the "<b>Luminate Health Trademarks</b>") used and displayed on this Service are registered and unregistered trademarks or service marks of Luminate Health. Other company, product, and service names located on the Service may be trademarks or service marks owned by third-parties (the "<b>Third-Party Trademarks</b>", and, collectively with Luminate Health Trademarks, the "<b>Trademarks</b>"). Nothing on this Service or in this Agreement should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on this Service without the prior written consent of Luminate Health specific for each such use. The Trademarks may not be used to disparage Luminate Health or the applicable third-party, Luminate Health's or third-party's products or services, or in any manner (using commercially reasonable judgment) that may damage any goodwill in the Trademarks. Use of any Trademarks as part of a link to or from any Service is prohibited without Luminate Health's prior written consent. All goodwill generated from the use of any Luminate Health Trademark shall inure to Luminate Health's benefit.</p>
    <p>3.3. All software embodied in the Luminate Health website or otherwise used by Luminate Health to deliver the Service ("Software"), and all data generated through use of the Service ("Use Data"), is proprietary to us or to our third party licensors and are protected by copyright and other intellectual property laws. Except as otherwise expressly permitted by this Agreement, any use, copying, making derivative works, transmitting, posting, linking, deep linking, redistribution, sale, decompilation, modification, reverse engineering, translation or disassembly of the Software or Use Data is prohibited. You may be subject to criminal or civil penalties for violation of this paragraph.</p>
    <p>3.4. You agree not to: (a) take any action that imposes an unreasonable load on the Service's infrastructure, (b) use any device, software or routine to interfere or attempt to interfere with the proper working of the Service or any activity being conducted on the Service, (c) attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or making up the Service, (d) delete or alter any material posted on the Service by Luminate Health or any other person or entity, or (e) frame or link to any of the materials or information available on the Service.</p>
    <p>3.5. The Service may contain links to third-party Services ("<b>External Sites</b>") third party-owned content (e.g., articles, data feeds, abstracts, etc.). These links are provided solely as a convenience to you and not as an endorsement by us of the content on such External Sites. The content of such External Sites is developed and provided by others. You should contact the site administrator or Webmaster for those External Sites if you have any concerns regarding such links or any content located on such External Sites.</p>
    <p>We are not responsible for the content of any linked External Sites and do not make any representations regarding the content or accuracy of any materials on such External Sites. You should take precautions when downloading files from all Services to protect your computer from viruses and other destructive programs. If you decide to access any External Sites, you do so at your own risk.</p>
    <p>3.6. Certain elements of the Service are protected by trade dress, trademark, unfair competition, and other state and federal laws and may not be copied or imitated in whole or in part, by any means, including but not limited to, the use of framing or mirrors, except as otherwise expressly permitted by Section 3.1 of the Agreement. None of the Content for this Service may be retransmitted without the express written consent from Luminate Health for each and every instance.</p>
    <p>3.7. The Digital Millennium Copyright Act of 1998 (the "<b>DMCA</b>") provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you have any complaints or objections to material posted on the Service you (or your agent) may send us a notice requesting that the material be removed, or access to it blocked. Notices must meet the then current statutory requirements imposed by the DMCA (see http://www.loc.gov/copyright for details). Notices with respect to the Service should be sent our Designated Agent at the following address:</p>
    <p>By Mail:<br />
      Attn: Legal<br />
      Luminate Health<br />
      55 E 3rd Ave<br />
      San Mateo, CA 94401
    </p>
    <p>By Email:<br />
    <a href="mailto:admin@luminatehealth.com" :style="linkPrimaryStyle">admin@luminatehealth.com</a></p>
    <p>Any notice alleging that materials hosted by or distributed through the Service infringe intellectual property rights must include the following information:</p>
    <p>(a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other right being infringed;</p>
    <p>(b) a description of the copyrighted work or other intellectual property that you claim has been infringed;</p>
    <p>(c) a description of the material that you claim is infringing and where it is located on the Service;(d) your address, telephone number, and email address;</p>
    <p>(e) a statement by you that you have a good faith belief that the use of the materials on the Service of which you are complaining is not authorized by the copyright owner, its agent, or the law; and</p>
    <p>(f) a statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">4. Your Account</h2>
    <p>4.1. You must provide accurate and complete registration information any time you register to use the Service. It is your responsibility to provide us with true, accurate and complete e-mail address, contact, and other information related to your account, and to maintain and update promptly any changes in this information.</p>
    <p>4.2. You are responsible for maintaining the confidentiality and security of your password and account, and you are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Luminate Health of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. Luminate Health shall not be liable for any loss or damage arising from your failure to comply with any of these terms and conditions.</p>
    <p>4.3. The following actions are expressly prohibited in relation to your username and password:</p>
    <p>(i) Sharing, disclosing, permitting access to or otherwise facilitating the use by any person of your username and password;</p>
    <p>(ii) Using the username and password to cache the Service in such a manner as to be accessible by persons who have not registered with Luminate Health; or</p>
    <p>(iii) Using the username and password to permit multiple persons access to the Service through a local or wide area network.</p>
    <p>4.4. If you have forgotten your username or password, the Service will use an email address provided by you to send your username or temporary password. You understand that others using the same email address will be able to gain access to your account information.</p>
    <p>4.5. Your use of the Service and any content accessed through the Service must comply with all applicable laws, regulations and ordinances, including any laws regarding the export of data or software. You must be at least 18 years old to register and use the Service, or You must be the legal guardian for someone under age 18 whose data is accessed through the Account Service. You may not interfere with or disrupt the proper operation of the Luminate Health Account Service.</p>
    <p>4.6. If you use a mobile device Luminate Health's to enter and maintain your personal information, you understand that you are responsible for safeguarding and securing your mobile device and the associated credentials (such as user identifiers and passwords). If you leave your mobile device unattended, or if it is lost or stolen, you understand that your personal information may be accessible to others.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">5. Limitation of Liability and Disclaimer of Warranties</h2>
    <p>5.1. LUMINATE HEALTH, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS (COLLECTIVELY, THE "<b>COMPANY PARTIES</b>") MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENT, INCLUDING BUT NOT LIMITED TO ITS ACCURACY, RELIABILITY, COMPLETENESS, TIMELINESS OR RELIABILITY. THE LUMINATE HEALTH PARTIES SHALL NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY OR COMPLETENESS OF THE CONTENT OR ANY OTHER INFORMATION CONVEYED TO THE USER OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE. YOU AGREE THAT YOU USE THE SERVICE AND THE CONTENT AT YOUR OWN RISK.</p>
    <p>THE LUMINATE HEALTH PARTIES DO NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR FREE OR THAT THE SERVICE, ITS SERVER, OR THE CONTENT ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICE OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, NO LUMINATE HEALTH PARTY SHALL BE RESPONSIBLE FOR THOSE COSTS.</p>
    <p>THE SERVICE AND CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND. THE LUMINATE HEALTH PARTIES DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NON INFRINGEMENT OF THIRD PARTIES RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.</p>
    <p>5.2. IN NO EVENT SHALL ANY LUMINATE HEALTH PARTY BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE SERVICE AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF SUCH LUMINATE HEALTH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
    <p>5.3. Some states do not allow exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations or exclusions may not apply to you. IN SUCH STATES, THE LIABILITY OF THE LUMINATE HEALTH PARTIES SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">6. Indemnification</h2>
    <p>You agree to defend, indemnify, and hold harmless the Luminate Health Parties from and against any claims, actions or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from your breach of this Agreement or your access to, use or misuse of the Content or the Service. Luminate Health shall provide notice to you of any such claim, suit, or proceeding. Luminate Health reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting Luminate Health's defense of such matter.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">7. Termination of the Agreement</h2>
    <p>7.1. Luminate Health reserves the right, in its sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Service or the Content at any time and for any reason without prior notice or liability. Luminate Health reserves the right to change, suspend, or discontinue all or any part of the Service or the Content at any time without prior notice or liability.</p>
    <p>7.2. Sections 3 (Use of the Service), 4 (Your Account), 5 (Limitation of Liability and Warranty), 6 (Indemnification), 7 (Termination of Agreement), and 10 (Miscellaneous) shall survive the termination of this Agreement.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">8. User Must Comply with Applicable Laws</h2>
    <p>8.1. This Service is hosted in the United States. We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States. If you access the Service or the Content from outside of the United States, you do so at your own risk. Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.</p>
    <p>8.2. The United States controls the export of products and information. You expressly agree to comply with such restrictions and not to export or re-export any of the Content to countries or persons prohibited under the export control laws. By downloading the Content, you are expressly agreeing that you are not in a country where such export is prohibited or are a person or entity for which such export is prohibited. You are solely responsible for compliance with the laws of your specific jurisdiction regarding the import, export, or re-export of the Content.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">9. U.S. Government Restricted Rights</h2>
    <p>The Content is provided with "RESTRICTED RIGHTS." Use, duplication, or disclosure by the Government is subject to the restrictions contained in 48 CFR 52.227-19 and 48 CFR 252.227-7013 et seq. or its successor. Use of the Service or Content by the Government constitutes acknowledgement of our proprietary rights in the Service and Content.</p>
    <hr :style="hrStyle">

    <h2 :style="[whitelabel ? headerColorStyleWhitelabel : headerColorStyle]">10. Miscellaneous</h2>
    <p>This Agreement is governed by the internal substantive laws of the State of California, without respect to its conflict of laws provisions. You expressly agree to submit to the exclusive personal jurisdiction of the state and federal courts sitting in the City of San Francisco in the State of California. If any provision of this Agreement is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. Failure of Luminate Health to act on or enforce any provision of the Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement. No waiver shall be effective against Luminate Health unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. Except as expressly agreed by Luminate Health and you, this Agreement constitutes the entire Agreement between you and Luminate Health with respect to the subject matter, and supercedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter. The section headings are provided merely for convenience and shall not be given any legal import. This Agreement will inure to the benefit of our successors, assigns, licensees, and sublicensees. Any information submitted or provided by you to the Service might be publicly accessible. Important and private information should be protected by you. Luminate Health is not liable for protection of privacy of electronic mail or other information transferred through the Internet or any other network that you may use.</p>
    <hr :style="hrStyle">

  </div>
</template>

<script>
import tinycolor from 'tinycolor2'

export default {
  name: 'Terms',
  data() {
    return {
      name: this.$root.config.name,
      whitelabel: this.$root.config.whitelabel,
      headerColorStyle: {
        color: this.$root.config.textColorPrimary
      },
      headerColorStyleWhitelabel: {
        color: this.$root.config.primaryColor
      },
      textPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkPrimaryStyle: {
        color: this.$root.config.textColorPrimary
      },
      linkSecondaryStyle: {
        color: this.$root.config.textColorSecondary
      },
      hrStyle: {
        borderTop: '1px solid' + tinycolor(this.$root.config.primaryColor).lighten(20).toHexString()
      }
    }
  }
}
</script>

<style scoped>
  h2, p, hr {
    width: 90%;
    max-width: 960px;
    text-align: left;
  }

  h6 {
    margin: 0;
  }

  hr {
    border: none;
  }

  a {
   text-decoration: underline; 
  }
</style>