<template>
  <div id="background-gradient" :class="[whitelabel ? 'opacity-low' : 'opacity-high']"></div>
</template>

<script>
import tinycolor from 'tinycolor2'

export default {
  name: 'BackgroundGradient',
  data() {
    return {
      whitelabel: this.$root.config.whitelabel,
      primaryColor: this.$root.config.primaryColor,
      colors: [
        tinycolor('#efeff4').lighten(10).toHexString(),
        tinycolor('#efeff4').toHexString(),
        tinycolor(this.$root.config.primaryColor).toHexString(),
        tinycolor(this.$root.config.primaryColor).lighten(10).toHexString()
      ]
    }
  },
  mounted() {
    let gradientStringOne = 'radial-gradient(circle at top, '
    for (let i = 0; i < this.colors.length; i++) {
      gradientStringOne += this.colors[i]
      if(i === this.colors.length -1) {
        gradientStringOne += ')'
      } else {
        gradientStringOne += ', '
      }
    }
    this.$el.style.backgroundImage = gradientStringOne
  }
}
</script>

<style scoped>
  #background-gradient {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  .opacity-high {
    opacity: 0.2;
  }

  .opacity-low {
    opacity: 0.1;
  }
</style>