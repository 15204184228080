<template>
  <div class="container">
    <Header />
      <h1 class="head">Purchase history</h1>
      <div class="order-box">
        <ul class="order-list">
          <transition-group name="fade">
            <OrderItem
            v-for="orderItem in paginatedData"
            :orderItem="orderItem"
            :key="orderItem.id"
            class="order-item"
          />
          </transition-group>
        </ul>
      </div>
      <div class="pagination-group">
        <btn btnColor="btn-pagination " @click="prevPage" :disabled="latestPage">
            &#8249;
        </btn>
       
        <btn btnColor="btn-pagination" @click="nextPage" :disabled="lastPage">
            &#8250;
        </btn>
      </div> 
      <btn btnColor="btn btn-medium btn-common" class='back-btn' @click="backToMain">Back to tests</btn>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Header from '../Header.vue'
import OrderItem from './OrderItem.vue'
import btn from '../Button.vue'
export default {
  data(){
    return {
      pageNumber: 0,
      pageSize: 3,      
    }
  },
  
  components: { OrderItem, Header,btn },
  computed: {
    ...mapGetters([  'paymentIntentId','orderId', 'orders' ]),
    paginatedData(){
      let start = this.pageNumber * this.pageSize,
          end = start + this.pageSize;
        return this.orders.slice(start, end);
    },
    latestPage(){
      return this.pageNumber==0;
    },
    pageCount(){
      let l = this.orders.length,
          s = this.pageSize;
      return Math.ceil(l/s);
    },
    lastPage(){
      return this.pageNumber>=this.pageCount-1;
    }
  },
 
  methods: {
    ...mapActions([ 'refund', 'getPatientOrder' ]),
    mounted(){
      this.getPatientOrder();
    },
    backToMain(){
      this.$router.push('alverno') 
    },
    nextPage(){
      this.pageNumber++;
    },
    prevPage(){
      this.pageNumber--;
    },
    
  }
    
}
</script>

<style lang="scss" scoped>
.table {
  font-size: 1.1rem;
  width: 500%;
  max-width: 1000px;
  display: center;
  margin-left: 110px;
}
.order-box {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    
}
.order-item {
  display: grid;
  width:1000px;
  grid-template-columns: 2fr 2fr 2fr;
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 0px 10px rgba(47, 51, 66, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px;
  margin: 0.7em 0;
}
 
.back-btn {
  width:250px;
}
.payment-btn {
  width:250px;
  margin-left: 600px;
}
  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
    
  }
  .fade-enter, .fade-leave-to {
    transform: translateX(40px);
    opacity: 0;
  }
  .head{
    color: #2e2f32;
    font-family: BogleWeb,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
    margin-right: 750px;
    margin-top: 50px;
    float: left;
    white-space: normal;
  }
  .pagination-group{
    min-width: 200px;
  }
</style>